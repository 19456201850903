import React from 'react'
import Select from '../../Select'
import {getChangeEventValue} from '../../../helpers/misc'
import useTheme from 'hooks/useTheme'
import {useHistory} from 'react-router-dom'
import {getPlatformUrl} from 'helpers/party'
import {partyMembersAtom, selectedPartyMemberAtom} from 'atoms/party'
import {useAtom, useAtomValue} from 'jotai'

const PartySelect: React.FC<React.PropsWithChildren> = () => {
  const history = useHistory()
  const theme = useTheme()
  const partyMembers = useAtomValue(partyMembersAtom)
  const [selectedPartyMember, setSelectedPartyMember] = useAtom(selectedPartyMemberAtom)

  if (partyMembers.length === 1) {
    return null
  }

  const handlePartyMemberClick = e => {
    const partyMemberId = getChangeEventValue(e).toString()
    const partyMember = partyMembers.find(partyMember => partyMember.id === +partyMemberId)
    setSelectedPartyMember(partyMember)

    localStorage.setItem('currentPartyMemberId', partyMemberId)
    history.push(getPlatformUrl(partyMember?.party?.partyRole, theme))
  }

  return (
    <Select
      data-cy="party-select"
      margin={{right: 2}}
      style={{border: `1px solid ${theme.colors.secondary}`, minWidth: 210}}
      value={selectedPartyMember?.id || undefined}
      options={
        partyMembers.length > 0
          ? partyMembers.map(partyMember => ({
              label: partyMember.party?.name,
              value: partyMember.id,
            }))
          : []
      }
      onChange={e => handlePartyMemberClick(e)}
    />
  )
}

export default PartySelect
