import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import Form from '../../Form'
import useLocalization from '../../../hooks/useLocalization'
import useServices from '../../../hooks/useServices'
import Heading from '../../Heading'
import Text from '../../Text'
import useTheme from 'hooks/useTheme'
import Box from 'components/Box'
import FormInput from 'components/Form/FormInput'
import FormSubmitButton from 'components/Form/FormSubmitButton'
import {StyledFormButton} from './Layout/FormComponents'
import FormPasswordInput from 'components/Form/FormPasswordInput'

const ForgotPasswordForm = () => {
  const theme = useTheme()
  const [email, setEmail] = useState(null)
  const {translate} = useLocalization()
  const {authenticationService} = useServices()
  const history = useHistory()

  const handleRequestForReset = async ({email}) => {
    await authenticationService.forgotPassword(email)
    setEmail(email)
  }

  const handleSubmit = async ({code, password}) => {
    await authenticationService.forgotPasswordSubmit(email, code, password)
    history.push('/')
  }

  if (email) {
    return (
      <div>
        <Heading size="medium" font={theme.font.headings}>
          {translate('Forgot your password?')}
        </Heading>
        <Text margin={{vertical: 2}} size="large" lineHeight="msmall">
          {translate('We have sent a password reset code by email. Enter it below to reset your password.')}
        </Text>

        <Form key={1} onSubmit={handleSubmit} submissionFeedback={null}>
          <FormInput large label={translate('Code')} name="code" required autoComplete="off" />
          <FormPasswordInput large name="password" />
          <FormInput
            large
            type="password"
            required
            label={translate('Repeat new password')}
            name="password2"
            validate={(value, getValues) => value === getValues().password || translate('Passwords do not match')}
            autoComplete="off"
          />

          <Box justify="flex-end" pad={{top: 2}}>
            <StyledFormButton variant="primary">{translate('Reset password')}</StyledFormButton>
          </Box>
        </Form>
      </div>
    )
  }

  return (
    <div>
      <Heading size="medium" font={theme.font.headings}>
        {translate('Forgot your password?')}
      </Heading>
      <Text margin={{vertical: 2}} size="large" lineHeight="msmall">
        {translate('Enter your Email below and we will send a message to reset your password')}
      </Text>

      <Form key={0} onSubmit={handleRequestForReset} submissionFeedback={null}>
        <FormInput large label={translate('Your email')} name="email" required />

        <Box justify="flex-end" pad={{top: 2}}>
          <StyledFormButton variant="primary">{translate('Reset password')}</StyledFormButton>
        </Box>
      </Form>
    </div>
  )
}

export default ForgotPasswordForm
