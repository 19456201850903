import {LinkButton} from 'components/Button'
import {getCurrencyCodeByPriceUnit} from 'helpers/price'
import React from 'react'
import {OptionsType} from 'react-select'
import styled from 'styled-components'
import IOffer, {INewOffer, OfferKind, OfferState} from 'domain/IOffer'
import {DateFormat, dateByMonthAndYear, formatDate, getPeriod} from 'helpers/date'
import {customerOfferStateToReadableName} from 'helpers/offer'
import {getPartyLink} from 'helpers/party'
import useLocalization from 'hooks/useLocalization'
import DottedIconButtonSelect from 'components/IconButton/DottedIconButtonSelect'
import {ModalType} from 'components/Modal/IModal'
import SmartOption, {ISmartOption} from 'components/MultiSelect/SmartOption'
import Table, {TableValue} from 'components/Table'
import {partyAtom} from 'atoms/party'
import {useAtomValue} from 'jotai'

const DropdownDiv = styled.div`
  width: 60px;
`

interface IProps extends React.PropsWithChildren {
  offers: INewOffer[]
  isLoading?: boolean
}

const CustomerCertOfferTable: React.FC<IProps> = ({offers, isLoading}) => {
  const {translate} = useLocalization()
  const party = useAtomValue(partyAtom)
  const partyRole = party?.partyRole

  const offerLink = (id, state, kind, modal?) => {
    if (kind === OfferKind.CERTIFICATE_TRADE) {
      return `/${getPartyLink(partyRole)}/buy/certificate-designer/offer/${id}/product?${modal || ''}`
    }

    return `/${getPartyLink(partyRole)}/buy/offers/${id}?${ModalType.OFFER_PDF}`
  }

  const getSelectOptions = (offer: IOffer): OptionsType<ISmartOption> => {
    if ([OfferState.CONFIG_SENT_FOR_SELLER_REVIEW, OfferState.SELLER_DECLINED_CONFIG].includes(offer.state)) {
      return [
        {
          value: 'Preview',
          to: offerLink(offer.id, offer.state, offer.kind),
          label: translate('Preview'),
        },
      ]
    } else if (offer.state === OfferState.CONFIG_IN_PROGRESS) {
      return [
        {
          value: 'Request',
          to: offerLink(offer.id, offer.state, offer.kind, ModalType.CERTIFICATE_REQUEST),
          label: translate('Request'),
        },
        {
          value: 'Preview',
          to: offerLink(offer.id, offer.state, offer.kind, ModalType.CERTIFICATE_PREVIEW),
          label: translate('Preview'),
        },
      ]
    } else {
      return [
        {
          value: 'Sign',
          to: offerLink(offer.id, offer.state, offer.kind),
          label: translate('Sign'),
        },
        {
          value: 'View',
          to: offerLink(offer.id, offer.state, offer.kind),
          label: translate('View'),
        },
      ]
    }
  }

  return (
    <Table<INewOffer>
      data={offers}
      isLoading={isLoading}
      linkTo={offer => offerLink(offer.id, offer.state, offer.kind)}
      highlightOnHover
      columns={[
        {
          title: translate('Name'),
          accessor: offer => offer.name,
          render: (name: string) => (
            <LinkButton style={{justifyContent: 'left', whiteSpace: 'nowrap'}} fullWidth>
              {name}
            </LinkButton>
          ),
        },
        {
          title: translate('Broker'),
          accessor: (offer: INewOffer) => offer.seller?.name,
          render: (value: string) => <strong>{value}</strong>,
        },
        {
          title: translate('Certificates'),
          accessor: (offer: INewOffer) => offer.contract?.volumeMwh,
          render: (volume: number) => <TableValue rounded value={volume} />,
        },
        {
          title: translate('Offer expires'),
          accessor: (offer: INewOffer) => formatDate(offer.validTo, DateFormat.MONTH_DAY_YEAR),
          render: (date: string) => <TableValue text={date} />,
        },
        {
          title: translate('Contract Term'),
          accessor: (offer: INewOffer) => getPeriod(offer.contract?.validFrom, offer.contract?.validTo),
          render: (term: string) => <TableValue text={term} />,
        },
        {
          title: translate('Purchase interval'),
          accessor: (offer: INewOffer) => {
            return (
              formatDate(
                dateByMonthAndYear(offer?.purchaseInterval?.month, offer?.purchaseInterval?.year),
                DateFormat.MONTH_YEAR,
              ) || '-'
            )
          },
          render: (interval: string) => <TableValue text={interval} />,
        },
        {
          title: translate('Certificate price'),
          accessor: (offer: INewOffer) => {
            return offer.certPrice
          },
          render: (price: number, offer: INewOffer) => (
            <TableValue value={price} unit={getCurrencyCodeByPriceUnit(offer.priceUnit)} />
          ),
        },
        {
          title: translate('Total price'),
          accessor: (offer: INewOffer) => offer.totalPrice,
          render: (price: number, offer: INewOffer) => (
            <TableValue nowrap value={price} unit={getCurrencyCodeByPriceUnit(offer.priceUnit)} />
          ),
        },
        {
          title: translate('Status'),
          accessor: (offer: INewOffer) => customerOfferStateToReadableName(offer.state, translate),
          render: (status: string) => <TableValue text={status} />,
        },
        {
          title: null,
          disableLink: true,
          accessor: (offer: any) => offer,
          render: (offer: any) => (
            <DropdownDiv>
              <DottedIconButtonSelect
                hideIndicators
                components={{Option: SmartOption}}
                options={getSelectOptions(offer)}
              />
            </DropdownDiv>
          ),
        },
      ]}
    />
  )
}

export default CustomerCertOfferTable
