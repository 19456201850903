import React, {useEffect, useState} from 'react'
import useLocalization from 'hooks/useLocalization'
import Form from 'components/Form'
import Page from 'components/Page'
import InnerScroll from 'components/InnerScroll'
import Box from 'components/Box'
import {useAtomValue, useSetAtom} from 'jotai'
import {externalContractAtom, externalContractIdAtom} from 'atoms/externalContracts'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import FormSubmitButton from 'components/Form/FormSubmitButton'
import useTabs from 'hooks/useTabs'
import ExternalContractUpload from './ExternalContractUpload'
import {TimeseriesKind} from 'domain/ISite'
import {rootServiceAtom} from 'atoms/general'
import {useHistory} from 'react-router-dom'
import {ProfileUploadKind} from 'services/V2ApiErrorMapper'
import {partyAtom} from 'atoms/party'
import ExternalContractViewForm from './ExternalContractViewForm'
import useAlerts from 'hooks/useAlerts'
import CenteredLoader from 'components/CenteredLoader'
import CenteredText from 'components/CenterText'

interface IProps extends React.PropsWithChildren {
  id: string
}

const TAB_SLUG = 'tab'
const UPLOAD_TAB = 'upload'

const ExternalContractPage: React.FC<IProps> = ({id}) => {
  const {translate} = useLocalization()
  const {addError, addSuccess} = useAlerts()
  const history = useHistory()
  const [uploadExpectedType, setUploadExpectedType] = useState(TimeseriesKind.BUILDER)
  const [uploadActualType, setUploadActualType] = useState(TimeseriesKind.BUILDER)
  const {currentTab} = useTabs(TAB_SLUG)
  const setContractId = useSetAtom(externalContractIdAtom)
  const {data: contract, loading, error} = useAtomValue(externalContractAtom)
  const rootService = useAtomValue(rootServiceAtom)
  const party = useAtomValue(partyAtom)

  useEffect(() => {
    setContractId(id)
  }, [])

  const handleSubmit = async (data, {setError}) => {
    const {actualBuilder, expectedBuilder, actualFile, expectedFile, interval} = data
    try {
      await rootService.v2ContentService.uploadExternalContractProduction(party.id, +id, {
        interval: {
          month: interval.month,
          year: +interval.year,
        },
        predictedTimeseries: {
          kind: uploadExpectedType,
          payload: {
            hourlyVolumes: uploadExpectedType === TimeseriesKind.BUILDER ? expectedBuilder.hourlyVolumes : undefined,
            attachmentId: uploadExpectedType === TimeseriesKind.FILE_UPLOAD ? expectedFile?.id : undefined,
          },
        },
        actualTimeseries: {
          kind: uploadActualType,
          payload: {
            hourlyVolumes: uploadActualType === TimeseriesKind.BUILDER ? actualBuilder.hourlyVolumes : undefined,
            attachmentId: uploadActualType === TimeseriesKind.FILE_UPLOAD ? actualFile?.id : undefined,
          },
        },
      })
      addSuccess(translate('Successfully saved %s', 'contract'))
      history.push('/consumer/portfolio/external-contracts')
    } catch (e) {
      if (e?.error?.kind in ProfileUploadKind) {
        const attachmentId = e.error.payload.attachmentId
        setError(actualFile?.id === attachmentId ? 'actualFile' : 'expectedFile', 'file', e.message)
        addError(e.message)
      } else {
        addError(translate('Failed to upload production'), e?.correlationId, e?.message)
      }
    }
  }

  if (loading) {
    return <CenteredLoader />
  }

  if (error) {
    return <CenteredText text={translate('Something went wrong')} />
  }

  return (
    <Form onSubmit={handleSubmit} fillHeight submissionFeedback={null}>
      <Page
        title={translate('External contract')}
        description={translate('Manage information about the external contract')}
        corner={
          currentTab === UPLOAD_TAB && (
            <Box gap={2}>
              <FormSubmitButton>{translate('Save')}</FormSubmitButton>
            </Box>
          )
        }
      >
        <InnerScroll noRightPad>
          <Box margin={{top: -2}}>
            <Tabs name={TAB_SLUG}>
              <Tab label={translate('General')} slug="">
                <Box width="50%" pad={2} border round>
                  <ExternalContractViewForm contract={contract} />
                </Box>
              </Tab>
              <Tab label={translate('Upload production')} slug={UPLOAD_TAB}>
                <ExternalContractUpload
                  uploadActualType={uploadActualType}
                  setUploadActualType={setUploadActualType}
                  uploadExpectedType={uploadExpectedType}
                  setUploadExpectedType={setUploadExpectedType}
                />
              </Tab>
            </Tabs>
          </Box>
        </InnerScroll>
      </Page>
    </Form>
  )
}

export default ExternalContractPage
