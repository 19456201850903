import Box from 'components/Box'
import DropdownMenu, {DropdownMenuButton, DropdownMenuLink} from 'components/Dropdown'
import Table from 'components/Table'
import {IDetailedPartyMemberWithUsers} from 'domain/IDetailedPartyMemberWithUsers'
import {ManagedUserAction, ManagedUserStatus} from 'domain/IManagedUser'
import useLocalization from 'hooks/useLocalization'
import useProfile from 'hooks/useProfile'
import React from 'react'

interface IProps extends React.PropsWithChildren {
  partyMembers: IDetailedPartyMemberWithUsers[]
  urlPrefix: string
  handleDisableUser: (asPartyId: number, managedUserId: number) => void
  handleResendInvite: (asPartyId: number, managedUserId: number) => void
  handleEnableUser: (asPartyId: number, managedUserId: number) => void
}

const ManagePartyMembersTable: React.FC<IProps> = ({
  urlPrefix,
  partyMembers: users,
  handleDisableUser,
  handleEnableUser,
  handleResendInvite,
}) => {
  const {translate} = useLocalization()
  const {user, party} = useProfile()

  const getAvailableActions = (
    partyMember: IDetailedPartyMemberWithUsers,
  ): (typeof DropdownMenuButton | typeof DropdownMenuLink)[] => {
    let status = null
    if (!partyMember.user.activated) {
      status = ManagedUserStatus.Invited
    } else {
      status = partyMember.user.enabled ? ManagedUserStatus.Active : ManagedUserStatus.Suspended
    }
    let availableAction
    switch (status) {
      case ManagedUserStatus.Active:
        availableAction = (
          <DropdownMenuButton height={2} onClick={() => handleDisableUser(party.id, partyMember.detMember.member.id)}>
            {ManagedUserAction.Suspend}
          </DropdownMenuButton>
        )
        break

      case ManagedUserStatus.Invited:
        availableAction = (
          <DropdownMenuButton height={2} onClick={() => handleResendInvite(party.id, partyMember.detMember.member.id)}>
            {ManagedUserAction.ResendInvite}
          </DropdownMenuButton>
        )
        break

      case ManagedUserStatus.Suspended:
        availableAction = (
          <DropdownMenuButton height={2} onClick={() => handleEnableUser(party.id, partyMember.detMember.member.id)}>
            {ManagedUserAction.Reactivate}
          </DropdownMenuButton>
        )
        break
    }

    const editAction = (
      <DropdownMenuLink to={`${urlPrefix}/company/members/edit/${partyMember.detMember.member.id}`}>
        {translate('Edit')}
      </DropdownMenuLink>
    )

    return [editAction, availableAction]
  }

  return (
    <Table<IDetailedPartyMemberWithUsers>
      data={users}
      pad={{bottom: 8}}
      search={false}
      highlightOnHover
      linkTo={managedPartyMember => `${urlPrefix}/company/members/edit/${managedPartyMember.detMember.member.id}`}
      columns={[
        {title: translate('Email'), accessor: (partyMember: IDetailedPartyMemberWithUsers) => partyMember?.user?.email},
        {
          title: translate('Name'),
          accessor: (managedPartyMember: IDetailedPartyMemberWithUsers) => {
            return managedPartyMember?.user?.firstName + ' ' + managedPartyMember?.user?.lastName
          },
        },
        {
          title: translate('Role'),
          accessor: (managedPartyMember: IDetailedPartyMemberWithUsers) =>
            translate(managedPartyMember?.detMember?.role),
        },
        {
          title: translate('Status'),
          accessor: (managedPartyMember: IDetailedPartyMemberWithUsers) => {
            if (!managedPartyMember.user.activated) {
              return translate('Invited')
            } else {
              return managedPartyMember.user.enabled ? translate('Active') : translate('Inactive')
            }
          },
        },
        {
          title: translate('Actions'),
          disableLink: true,
          accessor: (managedPartyMember: IDetailedPartyMemberWithUsers) => managedPartyMember,
          render: managedPartyMember => {
            return (
              <Box width={8} justify={'center'}>
                {managedPartyMember.user.email === user.email ? (
                  translate('(you)')
                ) : (
                  <DropdownMenu variant={'dottedIcon'} menuItems={getAvailableActions(managedPartyMember)} />
                )}
              </Box>
            )
          },
        },
      ].filter(Boolean)}
    />
  )
}

export default ManagePartyMembersTable
