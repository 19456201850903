import React from 'react'
import useLocalization from 'hooks/useLocalization'
import FormInput from './FormInput'
import {StyledFormInput} from 'components/Platform/Onboarding/Layout/FormComponents'

const FormPasswordInput = ({
  styled,
  large,
  placeholder,
  name,
}: {
  styled?: boolean
  large?: boolean
  placeholder?: string
  name: string
}) => {
  const {translate} = useLocalization()

  const FormInputComponent = styled ? StyledFormInput : FormInput

  return (
    <FormInputComponent
      large={large}
      type="password"
      required
      label={translate('New password')}
      placeholder={placeholder}
      name={name}
      autoComplete="off"
      minLength={8}
      data-cy="password"
      validate={(value: string): string | true => {
        if (value.length < 8) {
          return translate('Must be at least 8 characters.')
        }

        if (!/[a-z]/.test(value)) {
          return translate('Must contain at least one lowercase character.')
        }

        if (!/[0-9]/.test(value)) {
          return translate('Must contain at least one number.')
        }

        if (!/[A-Z]/.test(value)) {
          return translate('Must contain at least one uppercase character.')
        }

        if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value)) {
          return translate('Must contain at least one special character.')
        }

        return true
      }}
    />
  )
}

export default FormPasswordInput
