import ITheme from './ITheme'
import baseTheme from './base'

const basilTheme: ITheme = {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    globalBackground: '#F5F0EB',
    secondary: '#232D32',
    primary: '#8CC896',
    primaryDark: '#A0D2A5',
    primaryLight: '#a3d3ab',
  },
  logo: {
    ...baseTheme.logo,
    height: 4,
  },
  poweredBy: true,
}

export default basilTheme
