import ITheme from './ITheme'
import baseTheme from './base'
import BASIL from './basil'

const theme: ITheme = baseTheme

export function getTheme(themeName: string, logoUrl: string): ITheme {
  switch (themeName) {
    case 'BASIL':
      return {
        ...BASIL,
        logo: {
          ...BASIL.logo,
          logo: logoUrl,
        },
      }
    default:
      return baseTheme
  }
}

export default theme
