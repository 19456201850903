import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Box from 'components/Box'
import {FormControlLabel, makeStyles, Switch as MuiSwitch} from '@mui/material'
import useTheme from 'hooks/useTheme'

interface IProps extends React.PropsWithChildren {
  checked: boolean
  onChange: () => void
  label: string
}

const Switch: React.FC<IProps> = ({checked, onChange, label}) => {
  const theme = useTheme()
  return (
    <FormControlLabel
      control={
        <MuiSwitch
          sx={{
            '& .Mui-checked': {
              '& .MuiSwitch-thumb': {
                color: theme.colors.primary,
              },
              '& + .MuiSwitch-track': {
                backgroundColor: `${theme.colors.primary} !important`,
              },
            },
            '&.MuiSwitch-root .Mui-checked': {
              color: theme.colors.primary,
            },
            '&.MuiSwitch-root .MuiSwitch-track': {
              backgroundColor: theme.colors.primary,
            },
          }}
          checked={checked}
          onChange={onChange}
        />
      }
      label={label}
    />
  )
}

export default Switch
