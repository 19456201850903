import React from 'react'
import {IntlProvider} from 'react-intl'
import ThemeProvider from './components/ThemeProvider'
import theme from './theme'
import {ScreenSizeProvider} from './hooks/useScreenSize'
import useServices from './hooks/useServices'
import ThemeFavicon from './components/ThemeFavicon'
import {BrowserRouter} from 'react-router-dom'
import useLocalization from 'hooks/useLocalization'
import FeatureFlagProvider from 'components/FeatureFlagProvider'
import {PaywallProvider} from 'hooks/usePaywall'
import env from '@beam-australia/react-env'

const Providers: React.FC<React.PropsWithChildren> = ({children}) => {
  const services = useServices()

  return (
    <ThemeProvider theme={theme}>
      <ThemeFavicon />
      <ScreenSizeProvider>
        <IntlProviderWrapper>
          <FeatureFlagProvider>
            <PaywallProvider>
              <BrowserRouter>{children}</BrowserRouter>
            </PaywallProvider>
          </FeatureFlagProvider>
        </IntlProviderWrapper>
      </ScreenSizeProvider>
    </ThemeProvider>
  )
}

const IntlProviderWrapper: React.FC<React.PropsWithChildren> = ({children}) => {
  const {locale} = useLocalization()
  return <IntlProvider locale={locale || env('LANGUAGE')}>{children}</IntlProvider>
}

export default Providers
