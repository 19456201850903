import React, {useEffect, useState} from 'react'
import {FormControlLabel, RadioGroup, Skeleton} from '@mui/material'
import {PaddleEventData} from '@paddle/paddle-js'
import {paddleAtom} from 'atoms/paddle'
import {partyAtom, userAtom} from 'atoms/party'
import Box from 'components/Box'
import Page from 'components/Page'
import {PricingTiers, Tier} from 'components/PaywallDialog'
import Radio from 'components/Radio'
import Text from 'components/Text'
import {ISubscriptionPlan} from 'domain/IParty'
import useTheme from 'hooks/useTheme'
import {useAtomValue} from 'jotai'
import {Redirect} from 'react-router-dom'
import useLocalization from '../../../../hooks/useLocalization'
import env from '@beam-australia/react-env'
import {subscriptionsAtom} from 'atoms/subscription'
import {SubscriptionStatus} from 'domain/ISubscription'
import {companyInfoAtom} from 'atoms/company'
import useAlerts from 'hooks/useAlerts'

enum products {
  PRO = 0,
  ENTERPRISE = 1,
}

const Subscription = () => {
  const {translate} = useLocalization()
  const [proPrice, setProPrice] = useState('')
  const [enterprisePrice, setEnterprisePrice] = useState('')
  const [billingCycle, setBillingCycle] = useState('month')
  const party = useAtomValue(partyAtom)
  const {data: companyInfo} = useAtomValue(companyInfoAtom)
  const user = useAtomValue(userAtom)
  const theme = useTheme()
  const {addError, addInfo} = useAlerts()
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const {data: paddleData, loading: paddleInstanceLoading} = useAtomValue(paddleAtom)
  const {data: listOfSubscriptions} = useAtomValue(subscriptionsAtom)
  const {paddleInstance, monthList, yearList} = paddleData || {}

  const basicSubscription = party?.subscriptionPlan === ISubscriptionPlan.BASIC
  const isSandbox = env('PADDLE_ENVIRONMENT') === 'sandbox'

  useEffect(() => {
    setCheckoutLoading(false)
  }, [party?.subscriptionPlan])

  const getPrices = async (cycle: string) => {
    if (!paddleInstance) {
      return
    }

    const itemsList = cycle === 'month' ? monthList : yearList
    setBillingCycle(cycle)

    const request = {
      items: itemsList,
    }

    try {
      const result = await paddleInstance?.PricePreview(request)
      const items = result?.data?.details?.lineItems

      for (const item of items) {
        if (item.price.id === itemsList[products.PRO].priceId) {
          setProPrice(item.formattedTotals.subtotal)
        } else if (item.price.id === itemsList[products.ENTERPRISE].priceId) {
          setEnterprisePrice(item.formattedTotals.subtotal)
        }
      }
    } catch (error) {
      addError('Something went wrong please try again later')
    }
  }

  const openCheckout = (items: any) => {
    paddleInstance?.Checkout.open({
      items: [items],
      customData: {
        partyId: party?.id,
        userId: user?.id,
      },
      customer: {
        email: user?.email,
        business: {
          name: party?.name,
          taxIdentifier: companyInfo?.vatCode,
        },
      },
    })
  }

  useEffect(() => {
    if (paddleInstance && (!proPrice || !enterprisePrice)) {
      getPrices(billingCycle)
    }
  }, [paddleInstance])

  useEffect(() => {
    if (paddleInstance) {
      paddleInstance.Update({
        eventCallback: (event: PaddleEventData) => {
          if (event?.data?.status === 'completed' && event?.name == 'checkout.completed') {
            setCheckoutLoading(true)
          }
        },
      })
    }
  }, [paddleInstance])

  const tiers: Tier[] = [
    {
      name: 'Pro',
      price: proPrice,
      features: [
        'Full support during onboarding',
        'Up to 10 Consumption point',
        '10 External contract (PPA or Green tariff)',
        'Ideal for 10-50 GWh annual energy consumption',
      ],
      discount: false,
      buttonText: 'Subscribe',
      onClick: () => {
        openCheckout(billingCycle === 'month' ? monthList[products.PRO] : yearList[products.PRO])
      },
    },
    {
      name: 'Enterprise',
      price: enterprisePrice,
      features: [
        'Full support during onboarding',
        'Multiple External contract (PPA or Green tariff)',
        'Customised reporting',
        'Special offers depending on contract length',
        'Ideal for 51+ GWh annual energy consumption',
      ],
      discount: false,
      buttonText: 'Subscribe',
      onClick: () => {
        openCheckout(billingCycle === 'month' ? monthList[products.ENTERPRISE] : yearList[products.ENTERPRISE])
      },
    },
  ]

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    getPrices((event.target as HTMLInputElement).value)
  }

  if (paddleInstanceLoading || !proPrice || !enterprisePrice) {
    return (
      <Page title={translate('Subscription')} description={translate('View subscription')}>
        <Box direction="column" gap={2} align="center">
          <Skeleton variant="text" width="60%" height={40} />
          <Box direction="row" gap={2} justify="center">
            <Skeleton variant="rectangular" width={120} height={40} />
            <Skeleton variant="rectangular" width={120} height={40} />
          </Box>
          <Box direction="row" gap={2} justify="center">
            <Skeleton variant="rectangular" width={300} height={400} />
            <Skeleton variant="rectangular" width={300} height={400} />
          </Box>
        </Box>
      </Page>
    )
  }

  if (checkoutLoading) {
    return (
      <Page title={translate('Subscription')} description={translate('View subscription')}>
        <Box direction="column" gap={2} align="center">
          <Text size="medium" font={theme.font.headings}>
            Waiting for subscription completion
          </Text>
          <Skeleton variant="rectangular" width="80%" height={60} />
        </Box>
      </Page>
    )
  }

  if (
    !basicSubscription &&
    listOfSubscriptions?.data.filter(subscription => subscription.status === SubscriptionStatus.Active).length > 0
  ) {
    addInfo('You already have an active subscription')
    return <Redirect to="/consumer/company/subscription" />
  }

  return (
    <Page title={translate('Subscription')} description={translate('View subscription')}>
      <Box direction="column" gap={4} style={{marginLeft: 'auto', marginRight: 'auto'}}>
        {isSandbox && (
          <Box
            direction="column"
            gap={2}
            align="center"
            border
            round
            pad={{horizontal: 4, vertical: 2}}
            height="fit-content"
          >
            <Text size="large" bold>
              Test Mode
            </Text>
            <Text>
              This is a sandbox environment for demo purposes. To subscribe to a plan you have to use the following card
              number: <b>4000 0566 5566 5556</b> and CVC: <b>100</b>
            </Text>
          </Box>
        )}
        <Box
          direction="column"
          gap={2}
          align="center"
          border
          round
          pad={{horizontal: 4, vertical: 2}}
          height="fit-content"
        >
          <Text size="medium" font={theme.font.headings}>
            Upgrade your plan to unlock more features
          </Text>
          <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={billingCycle} onChange={handleChange}>
            <Box direction="row" gap={2} justify="center">
              <FormControlLabel value="month" control={<Radio />} label="Monthly" />
              <Box direction="row">
                <FormControlLabel style={{marginRight: 0}} value="year" control={<Radio />} label="Yearly" />
                <Text
                  style={{
                    border: '1px solid #82fa7a',
                    backgroundColor: theme.colors.primary,
                    height: 'fit-content',
                    borderRadius: '10px',
                    padding: '1px 4px',
                  }}
                  size="small"
                >
                  Save 20%
                </Text>
              </Box>
            </Box>
          </RadioGroup>
          <PricingTiers tiers={tiers} billingCycle={false} />
        </Box>
      </Box>
    </Page>
  )
}

export default Subscription
