import React, {useEffect, useState} from 'react'
import {Redirect} from 'react-router'
import Page from 'components/Platform/Onboarding/Page'
import InnerScroll from 'components/InnerScroll'
import OnboardingSteps, {Step} from 'components/Platform/Onboarding/OnboardingSteps'
import EmailVerificationForm from 'components/Platform/Onboarding/EmailVerificationForm'
import OnboardingCompleted from 'components/Platform/Onboarding/OnboardingCompleted'
import IParty from 'domain/IParty'
import CreateAccountForm from 'components/Platform/Onboarding/CreateAccountForm'
import ContactInfoForm from 'components/Platform/Onboarding/ContactInfoForm'
import VerticalStack from 'components/VerticalStack'
import {IUserProfile} from 'domain/IUserProfile'
import useProfile from 'hooks/useProfile'

const getCurrentStep = (user: IUserProfile, party: IParty): Step => {
  if (!user) {
    return Step.LOGIN_INFO
  } else if (!user?.activated) {
    return Step.EMAIL_VERIFICATION
  } else if (!party) {
    return Step.CONTACT_INFO
  } else if (!party.partyRole) {
    return Step.PARTY_VERIFICATION
  } else {
    return Step.COMPLETED
  }
}

const CreateAccount = () => {
  const {user, party, isLoaded} = useProfile()
  const [currentStep, setCurrentStep] = useState<Step>(getCurrentStep(user, party))
  const [contentHeight, setContentHeight] = useState(window?.innerHeight - 250)

  const updateContentHeight = () => {
    setContentHeight(window.innerHeight - 250)
  }

  useEffect(() => {
    updateContentHeight()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', updateContentHeight)
  }, [])

  useEffect(() => {
    setCurrentStep(getCurrentStep(user, party))
  }, [user, party])

  if (!isLoaded) {
    return null
  }

  return (
    <Page>
      <VerticalStack style={{height: `${contentHeight}px`}}>
        <InnerScroll noRightPad>
          <OnboardingSteps current={currentStep} />
          {currentStep === Step.LOGIN_INFO && <CreateAccountForm />}
          {currentStep === Step.EMAIL_VERIFICATION && <EmailVerificationForm />}
          {currentStep === Step.CONTACT_INFO && <ContactInfoForm />}
          {currentStep === Step.PARTY_VERIFICATION && <OnboardingCompleted />}
          {currentStep === Step.COMPLETED && <Redirect to="/" />}
        </InnerScroll>
      </VerticalStack>
    </Page>
  )
}
export default CreateAccount
