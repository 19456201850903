import {buyerSiteTimeseriesAtom} from 'atoms/portfolioSites'
import CenteredText from 'components/CenterText'
import useLocalization from 'hooks/useLocalization'
import useNewChartColors from 'hooks/useNewChartColors'
import {useAtomValue} from 'jotai'
import React from 'react'
import {autoCeilNumber, autoFloorNumber} from 'helpers/misc'
import Chart, {ChartType, getMaxValue, getMinValue, ILine, StackType} from 'components/Chart'
import Box from 'components/Box'
import {Skeleton} from '@mui/material'

interface IProps extends React.PropsWithChildren {
  siteName: string
}

const SiteDashboardChart: React.FC<IProps> = ({siteName}) => {
  const {data: timeseries, loading} = useAtomValue(buyerSiteTimeseriesAtom)
  const {translate} = useLocalization()

  const chartColors = useNewChartColors()

  if (loading) {
    return (
      <Box direction="row" height="256px" gap={3}>
        <Box width="90%">
          <Skeleton height={256} variant="rectangular"></Skeleton>
        </Box>
        <Box width="10%" gap={2} direction="column">
          <Box>
            <Skeleton variant="text" />
            <Skeleton height={40} variant="rectangular" />
          </Box>
        </Box>
      </Box>
    )
  }

  if (!timeseries) {
    return <CenteredText text={translate('No timeseries data available')} />
  }
  const lines: ILine[] = []

  const labels = timeseries?.timeseries?.map(({name}) => name)

  lines.push({
    key: `${timeseries.siteId}`,
    label: siteName,
    color: chartColors.getNext(),
    data: timeseries.timeseries.map(({value}) => value),
    type: ChartType.BAR,
    stackType: StackType.STACKED,
    fill: true,
  })

  return (
    <Chart
      toolbar={{vertical: false, timescale: {hideYearly: false, hideMonthly: false}}}
      labels={labels}
      lines={lines}
      dark
      maxValue={autoCeilNumber(getMaxValue(lines))}
      minValue={autoFloorNumber(getMinValue(lines))}
    />
  )
}

export default SiteDashboardChart
