import {atom} from 'jotai'
import defaultTranslations from 'localization/en.json'
import deTranslations from 'localization/de.json'
import {atomWithCompare} from './utils'

enum Locale {
  EN = 'en',
}

const DEFAULT_LOCALE = Locale.EN

export const localeAtom = atomWithCompare(DEFAULT_LOCALE)

export const translationsAtom = atom(get => {
  const locale = get(localeAtom)

  if (Locale.EN === locale) {
    return defaultTranslations
  }

  return defaultTranslations
})
