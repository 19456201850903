import {PrimitiveAtom, atom} from 'jotai'
import {loadable} from './utils'
import {partyAtom} from './party'
import {rootServiceAtom} from './general'
import {MarketplaceSort, Order} from 'domain/FilterType'
import {Country} from 'domain/ILocation'
import {SiteProductionTechnology} from 'domain/ISite'
import {getPrevMonth, getPrevMonthYear} from 'helpers/date'

const DEFAULT_PAGE_SIZE = 25
const DEFAULT_PAGE = 1
const DEFAULT_SORT = MarketplaceSort.ID
const DEFAULT_ORDER = Order.DESC
export const ALL_TECHNOLOGIES_SLUG = 'ALL'

export const pageAtom = atom(DEFAULT_PAGE)
export const pageSizeAtom = atom(DEFAULT_PAGE_SIZE)
export const sortAtom = atom(DEFAULT_SORT)
export const orderAtom = atom(DEFAULT_ORDER)
export const technologyAtom = atom<SiteProductionTechnology | typeof ALL_TECHNOLOGIES_SLUG>(ALL_TECHNOLOGIES_SLUG)

export const monthAtom = atom(getPrevMonth())
export const yearAtom = atom(getPrevMonthYear())
export const countriesAtom = atom<Country[]>([]) as PrimitiveAtom<Country[]>

export const productsAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const month = get(monthAtom)
    const year = get(yearAtom)

    const page = get(pageAtom)
    const pageSize = get(pageSizeAtom)
    const order = get(orderAtom)
    const sort = get(sortAtom)
    const countries = get(countriesAtom)
    const technology = get(technologyAtom)

    return await services.v2ContentService.getCertificateProducts(
      party.id,
      month,
      year,
      page,
      pageSize,
      sort,
      order,
      countries,
      technology === ALL_TECHNOLOGIES_SLUG ? undefined : technology,
    )
  }),
)
