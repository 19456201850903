import React, {useEffect, useState} from 'react'
import PhoneNumberVerificationModal from 'components/Platform/Account/PhoneNumberVerificationModal'
import ChangePasswordForm from 'components/Platform/Account/ChangePasswordForm'
import PersonalInformationForm from 'components/Platform/Account/PersonalInformationForm'
import useLocalization from 'hooks/useLocalization'
import Page from 'components/Page'
import ResponsiveGrid from 'components/ResponsiveGrid'
import {ScreenBreakpoint} from 'hooks/useScreenSize'
import Box from 'components/Box'
import {userAtom} from 'atoms/party'
import {useAtomValue} from 'jotai'

const PersonalProfile = () => {
  const {translate} = useLocalization()
  const user = useAtomValue(userAtom)
  const [showPhoneVerificationModal, setShowPhoneVerificationModal] = useState(false)

  useEffect(() => {
    if (user) {
      const showModal = !user?.phoneNumber && !user?.phoneNumberVerified
      setShowPhoneVerificationModal(showModal)
    }
  }, [user?.phoneNumberVerified])

  return (
    <Page title={translate('Personal profile')} description={translate('Update your personal profile')} noDivider>
      <ResponsiveGrid gap={2} templateColumns={{[ScreenBreakpoint.Large]: '1fr', [ScreenBreakpoint.MLarge]: '1fr 1fr'}}>
        <Box pad={2} round border $maxHeight={60}>
          <PersonalInformationForm setShowPhoneVerificationModal={setShowPhoneVerificationModal} />
        </Box>
        <Box pad={2} round border $maxHeight={60}>
          <ChangePasswordForm />
        </Box>
      </ResponsiveGrid>
      {showPhoneVerificationModal && (
        <PhoneNumberVerificationModal setShowPhoneVerificationModal={setShowPhoneVerificationModal} />
      )}
    </Page>
  )
}

export default PersonalProfile
