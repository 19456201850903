import React from 'react'
import Form from '../../Form'
import Label, {LabelWithIcon} from '../../Label'
import useLocalization from '../../../hooks/useLocalization'
import Text from '../../Text'
import Box from '../../Box'
import useServices from '../../../hooks/useServices'
import {StyledFormButton, StyledLocationSearch} from './Layout/FormComponents'
import {LocationBox} from '../Portfolio/Site/LocationSearch'
import FormInput from 'components/Form/FormInput'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {getAllowedCountries, getHumanReadableCountry} from 'helpers/location'
import FormVatCodeInput from 'components/Form/FormVatCodeInput'
import usePartyActions from 'hooks/usePartyActions'

// eslint-disable-next-line
interface IProps extends React.PropsWithChildren {}

const ContactInfoForm: React.FC<IProps> = () => {
  const {translate} = useLocalization()
  const {refreshParties} = usePartyActions()
  const {v2ContentService} = useServices()
  const allowedCountriesList = getAllowedCountries().map(country => getHumanReadableCountry(country, translate))

  const handleSubmit = async data => {
    const {name, registrationCode, vatCode, location} = data

    await v2ContentService.registerCreateParty({
      name,
      registrationCode,
      vatCode,
      placeId: location.placeId,
    })

    refreshParties()
  }

  return (
    <Form onSubmit={handleSubmit} submissionFeedback={null} fillHeight validationMode="onChange">
      <Box margin={{bottom: 3}}>
        <Label text={translate('Account type')}>
          <Text size="large" lineHeight="small">
            {translate('Corporate account')}
          </Text>
        </Label>
      </Box>

      <FormInput large label={translate('Company name')} name="name" required data-cy="name" />

      <FormInput large label={translate('Company reg. number')} name="registrationCode" data-cy="regNumber" required />

      <LocationBox data-cy="location-select">
        <LabelWithIcon
          text={translate('Company address')}
          Icon={<InfoOutlinedIcon sx={{height: '16px', width: '16px'}} />}
          tooltip={translate(`We currently support following countries: ${allowedCountriesList.join(', ')}`)}
        >
          <StyledLocationSearch location={null} variant="contrast" required allowedCountries={getAllowedCountries()} />
        </LabelWithIcon>
      </LocationBox>

      <FormVatCodeInput name="vatCode" large disableWithNoLocation triggerValidationOnLocationChange />

      <Box justify="flex-end" pad={{top: 3}}>
        <StyledFormButton variant="primary">{translate('Continue')}</StyledFormButton>
      </Box>
    </Form>
  )
}

export default ContactInfoForm
