import React from 'react'
import {Redirect} from 'react-router'
import SignInForm from 'components/Platform/Onboarding/SignInForm'
import Page from 'components/Platform/Onboarding/Page'
import {getPlatformUrl} from 'helpers/party'
import useTheme from 'hooks/useTheme'
import {useAtomValue} from 'jotai'
import {partyAtom, userAtom} from 'atoms/party'

const SignIn = () => {
  const user = useAtomValue(userAtom)
  const party = useAtomValue(partyAtom)
  const theme = useTheme()

  if (user) {
    return party?.partyRole ? <Redirect to={getPlatformUrl(party?.partyRole, theme)} /> : <Redirect to="/register" />
  }

  return (
    <Page>
      <SignInForm />
    </Page>
  )
}

export default SignIn
