import React from 'react'
import useLocalization from 'hooks/useLocalization'
import Table, {TableValue} from 'components/Table'
import {DateFormat, dateByMonthAndYear, formatDate, getPeriod} from 'helpers/date'
import {brokerOfferStateToReadableName} from 'helpers/offer'
import {getCurrencyCodeByPriceUnit} from 'helpers/price'
import DottedIconButtonSelect from 'components/IconButton/DottedIconButtonSelect'
import SmartOption, {ISmartOption} from 'components/MultiSelect/SmartOption'
import {OptionsType} from 'react-select'
import styled from 'styled-components'
import {INewOffer, OfferState} from 'domain/IOffer'
import {LinkButton} from 'components/Button'

interface IProps extends React.PropsWithChildren {
  offers: INewOffer[]
  loading?: boolean
  updateTable: () => void
}

const DropdownDiv = styled.div`
  width: 60px;
`

const OfferTable: React.FC<IProps> = ({offers, loading, updateTable}) => {
  const {translate} = useLocalization()

  const getSelectOptions = (offer: INewOffer): OptionsType<ISmartOption> => {
    if (offer.state === OfferState.CONTRACT_SIGNED) {
      return [
        {
          value: 'View',
          label: translate('View'),
          to: `/broker/sell/offers/${offer.id}?`,
        },
      ]
    } else {
      return [
        {
          value: 'View',
          label: translate('View'),
          to: `/broker/sell/offers/${offer.id}?`,
        },
      ]
    }
  }

  return (
    <Table<INewOffer>
      tableName={translate('Offers')}
      data={offers}
      isLoading={loading}
      linkTo={offer => `/broker/sell/offers/${offer.id}?`}
      highlightOnHover
      columns={[
        {
          title: translate('Name'),
          accessor: (offer: INewOffer) => offer.name,
          render: (name: string) => (
            <LinkButton style={{justifyContent: 'left', whiteSpace: 'nowrap'}} fullWidth>
              {name}
            </LinkButton>
          ),
        },
        {
          title: translate('Customer'),
          accessor: (offer: INewOffer) => offer.customer?.name,
          // eslint-disable-next-line react/display-name
          render: (value: string) => <strong>{value}</strong>,
        },
        {
          title: translate('Certificates'),
          accessor: (offer: INewOffer) => offer.contract?.volumeMwh,
          render: (volume: number) => <TableValue rounded value={volume} />,
        },
        {
          title: translate('Offer expires'),
          accessor: (offer: INewOffer) => formatDate(offer.validTo, DateFormat.MONTH_DAY_YEAR),
          render: (date: string) => <TableValue text={date} />,
        },
        {
          title: translate('Contract Term'),
          accessor: (offer: INewOffer) => getPeriod(offer.contract?.validFrom, offer.contract?.validTo),
          render: (term: string) => <TableValue text={term} />,
        },
        {
          title: translate('Purchase interval'),
          accessor: (offer: INewOffer) => {
            return (
              formatDate(
                dateByMonthAndYear(offer?.purchaseInterval?.month, offer?.purchaseInterval?.year),
                DateFormat.MONTH_YEAR,
              ) || '-'
            )
          },
          render: (interval: string) => <TableValue text={interval} />,
        },
        {
          title: translate('Certificate price'),
          accessor: (offer: INewOffer) => offer.certPrice,
          render: (price: number, offer: INewOffer) => (
            <TableValue value={price} unit={getCurrencyCodeByPriceUnit(offer.priceUnit)} />
          ),
        },
        {
          title: translate('Total price'),
          accessor: (offer: INewOffer) => offer.totalPrice,
          render: (price: number, offer: INewOffer) => (
            <TableValue nowrap value={price} unit={getCurrencyCodeByPriceUnit(offer.priceUnit)} />
          ),
        },
        {
          title: translate('Status'),
          accessor: (offer: INewOffer) => brokerOfferStateToReadableName(offer.state, translate),
        },
        {
          title: null,
          disableLink: true,
          accessor: (offer: any) => offer,
          // eslint-disable-next-line react/display-name
          render: (offer: INewOffer) => (
            <DropdownDiv>
              <DottedIconButtonSelect
                hideIndicators
                components={{Option: SmartOption}}
                options={getSelectOptions(offer)}
              />
            </DropdownDiv>
          ),
        },
      ]}
    />
  )
}

export default OfferTable
