import {partyAtom} from 'atoms/party'
import BuyerSiteCreatePage from 'components/Platform/Portfolio/Site/BuyerSiteCreatePage'
import BuyerSiteEditPage from 'components/Platform/Portfolio/Site/BuyerSiteEditPage'
import {useAtomValue, useSetAtom} from 'jotai'
import React, {useEffect} from 'react'
import SiteListPageNew from 'components/Platform/Portfolio/Site/SiteListPageNew'
import ResourceGateway from 'components/ResourceGateway'
import {PartyRole} from 'domain/IParty'
import {SiteType} from 'domain/ISite'
import SellerSiteCreatePage from 'components/Platform/Portfolio/Site/SellerSiteCreatePage'
import SellerSiteEditPage from 'components/Platform/Portfolio/Site/SellerSiteEditPage'
import {periodAtom, PeriodType, resetPeriodAtom, yearAtom} from 'components/PeriodSelect/periodSelect'
import dayjs from 'dayjs'

interface IProps extends React.PropsWithChildren {
  partyId?: number
  type?: SiteType
}

const Sites: React.FC<IProps> = ({partyId, type}) => {
  const party = useAtomValue(partyAtom)
  const {partyRole} = party || {}
  const isBuyer = partyRole === PartyRole.BUYER
  const resetPeriod = useSetAtom(resetPeriodAtom)
  const setPeriod = useSetAtom(periodAtom)
  const setYear = useSetAtom(yearAtom)

  useEffect(() => {
    const current = dayjs()
    setPeriod({period: PeriodType.MONTH, value: current.format('MMMM')})
    setYear(current.year())

    return () => resetPeriod()
  }, [])

  if (!party) {
    return null
  }

  return (
    <ResourceGateway
      create={() => (isBuyer ? <BuyerSiteCreatePage /> : <SellerSiteCreatePage />)}
      edit={routeProps =>
        isBuyer ? (
          <BuyerSiteEditPage siteId={routeProps.match.params.id} />
        ) : (
          <SellerSiteEditPage siteId={routeProps.match.params.id} />
        )
      }
      list={() => <SiteListPageNew />}
    />
  )
}

export default Sites
