import React, {useEffect} from 'react'
import {getCountryVatErrorMessage, getCountryVatRegex} from 'helpers/location'
import useLocalization from 'hooks/useLocalization'
import FormInput from './FormInput'
import useFormContext from 'hooks/useFormContext'

const FormVatCodeInput = ({
  name,
  large,
  disabled,
  disableWithNoLocation,
  triggerValidationOnLocationChange,
}: {
  name: string
  large?: boolean
  disabled?: boolean
  disableWithNoLocation?: boolean
  triggerValidationOnLocationChange?: boolean
}) => {
  const {translate} = useLocalization()
  const {watch, triggerValidation} = useFormContext()
  const location = watch('location.placeId')
  const locationSearch = watch('location.search')

  useEffect(() => {
    if (locationSearch && triggerValidationOnLocationChange) {
      triggerValidation('vatCode')
    }
  }, [locationSearch])

  const locationLocked = disableWithNoLocation ? !location : disabled

  return (
    <FormInput
      required
      validate={(value, formValues) => {
        const formData = formValues()

        const countryCode = formData?.['location.countryCode']

        if (!getCountryVatRegex(countryCode).test(value) || !countryCode) {
          return getCountryVatErrorMessage(countryCode)
        }

        return true
      }}
      large={large}
      label={translate('Company vat. number')}
      name={name}
      data-cy={name}
      disabled={disabled || locationLocked}
    />
  )
}

export default FormVatCodeInput
