import {localeAtom, translationsAtom} from 'atoms/localization'
import {useAtomValue} from 'jotai'
import {useCallback, useMemo} from 'react'

interface IUseLocalization {
  locale: string
  translate(text: string, ...modifiers: Array<string | number>): string
}

const useLocalization = (): IUseLocalization => {
  const translations = useAtomValue(translationsAtom)
  const locale = useAtomValue(localeAtom)

  const translate = useCallback(
    (text: any, translations: any, ...modifiers: any) => {
      let newText = text

      if (translations[text]) {
        newText = translations[text]
      }

      modifiers.forEach(modifier => {
        newText = newText.replace('%s', modifier ? modifier.toString() : '')
      })

      return newText
    },
    [translations],
  )

  return {
    locale,
    translate: (text, ...modifiers) => translate(text, translations, ...modifiers),
  }
}

export default useLocalization
