import {IUserProfile} from 'domain/IUserProfile'

export default [
  {
    id: 'user-1',
    firstName: 'First',
    lastName: 'Last',
    email: 'my@email.com',
    emailVerified: true,
    phoneNumber: '+12345678',
    phoneNumberVerified: true,
    authorities: ['ROLE_ADMIN', 'ROLE_USER'],
  },
] as unknown as IUserProfile[]
