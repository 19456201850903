import React, {useEffect, useState} from 'react'
import useLocalization from 'hooks/useLocalization'
import Page from 'components/Page'
import Box from 'components/Box'
import SiteMap from 'components/Platform/Map/SiteMap'
import InnerScroll from 'components/InnerScroll'
import {getSitesOfContracts} from 'helpers/contract'
import ContractsSummaryByTechnology from './ContractsSummaryByTechnology'
import SalesContractList from './SalesContractList'
import {useAtomValue} from 'jotai'
import {partyAtom} from 'atoms/party'
import {rootServiceAtom} from 'atoms/general'
import IPurchaseContract from 'domain/IPurchaseContract'

// TODO: move this to v2 and then to atoms
const SalesContractListPage: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const party = useAtomValue(partyAtom)
  const rootService = useAtomValue(rootServiceAtom)
  const [loading, setLoading] = useState(true)
  const [contracts, setContracts] = useState<IPurchaseContract[]>([])
  const sites = getSitesOfContracts(contracts)

  useEffect(() => {
    const getContracts = async () => {
      const contracts = await rootService.contentService.getSalesContracts(party?.id)
      setLoading(false)
      setContracts(contracts)
    }

    if (party) {
      getContracts()
    }
  }, [party])

  return (
    <Page
      title={translate('Sales contracts')}
      description={translate('Overview of sales contracts')}
      aside={
        <SiteMap
          title={translate('Production sites')}
          description={translate('Overview of contracted production sites')}
          sites={sites || []}
        />
      }
    >
      <InnerScroll>
        <ContractsSummaryByTechnology contracts={contracts} loading={loading} />
        <Box margin={{vertical: 4}} />
        <SalesContractList contracts={contracts} loading={loading} />
      </InnerScroll>
    </Page>
  )
}

export default SalesContractListPage
