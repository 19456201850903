import {rootServiceAtom} from 'atoms/general'
import {partyAtom, userAtom} from 'atoms/party'
import {greenCertificatesAtom, overviewDateFilter, overviewDateFiltermAtom} from 'atoms/portfolioOverview'
import Box from 'components/Box'
import Checkbox from 'components/Checkbox'
import Heading from 'components/Heading'
import InnerScroll from 'components/InnerScroll'
import {ModalType} from 'components/Modal/IModal'
import PeriodSelect from 'components/PeriodSelect'
import CertificatesChart from 'components/Platform/Portfolio/GreenCertificate/CertificatesChart'
import CertificateSummaryItem from 'components/Platform/Portfolio/GreenCertificate/CertificateSummaryItem'
import ExcessModal from 'components/Platform/Portfolio/GreenCertificate/ExcessModal'
import LoadingSkeleton from 'components/Platform/Portfolio/GreenCertificate/LoadingSkeleton'
import ResourceSummary from 'components/Platform/Portfolio/Summary/ResourceSummary'
import ResourceSummaryItem from 'components/Platform/Portfolio/Summary/ResourceSummaryItem'
import {SellExcessPayload} from 'domain/INewTimeseriesItem'
import {EnergyUnit} from 'helpers/conversion'
import useAlerts from 'hooks/useAlerts'
import useLocalization from 'hooks/useLocalization'
import usePartyActions from 'hooks/usePartyActions'
import useTheme from 'hooks/useTheme'
import {useAtomValue, useSetAtom} from 'jotai'
import React, {useState} from 'react'
import Joyride, {CallBackProps, STATUS} from 'react-joyride'

const GreenCertificates: React.FC<React.PropsWithChildren> = () => {
  const theme = useTheme()
  const {data: certData, loading, error} = useAtomValue(greenCertificatesAtom)
  const [filter, setFilter] = useState({showExcess: true, showMisisng: true})
  const party = useAtomValue(partyAtom)
  const {translate} = useLocalization()
  const {refreshParties} = usePartyActions()
  const {addError, addSuccess} = useAlerts()
  const {excess, missing} = certData?.summary || {}
  const hasData = certData?.consumption?.some(item => item.value > 0)
  const totalMissing = certData?.missing?.reduce((acc, item) => acc + item.value, 0)
  const totalExcess = certData?.excess?.reduce((acc, item) => acc + item.value, 0)
  const user = useAtomValue(userAtom)
  const rootStore = useAtomValue(rootServiceAtom)
  const {v2ContentService} = rootStore
  const setDateFilter = useSetAtom(overviewDateFilter)
  const setOverviewDateFilter = useSetAtom(overviewDateFiltermAtom)

  const steps = [
    {
      title: 'Welcome',
      target: 'a[href="/consumer/portfolio/external-contracts"]',
      content: `Let's start with setting your Energy supply contract or PPA`,
      disableBeacon: true,
      spotlightPadding: 10,
    },
    {
      target: '[data-cy="kpi-goals"]',
      content: `Once your External agreement is set, lets set your renewable energy targets for this year`,
      disableBeacon: true,
      spotlightPadding: 10,
      placement: 'left',
    },
    {
      target: 'a[href="/consumer/buy/marketplace/request"]',
      content: `Congratulations, your initial set up done, now lets achieve your goals together`,
      disableBeacon: true,
      spotlightPadding: 10,
      placement: 'left',
    },
  ]

  const handleExcessSell = async (payload: SellExcessPayload) => {
    await v2ContentService.sellCertExcessRequest(party.id, payload)
    addSuccess(translate('Successfully sent sell request'))
  }

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const {status, lifecycle} = data
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]

    if (finishedStatuses.includes(status) && lifecycle === 'complete') {
      try {
        await v2ContentService.completeBuyerGuide(user.id, user.email)
        await refreshParties()
      } catch (ex) {
        addError('Failed to complete onboarding', ex?.correlationId, ex?.message)
      }
    }
  }

  if (loading) {
    return <LoadingSkeleton />
  }

  return (
    <Box pad={{bottom: 2}}>
      <ExcessModal onSell={handleExcessSell} excess={excess} />
      <Joyride
        steps={steps.map(step => ({
          ...step,
          placement: step.placement === 'left' ? 'left' : 'auto',
        }))}
        run={!user?.buyerGuideCompletedOn}
        continuous={true}
        showSkipButton={true}
        locale={{
          last: 'Finish',
        }}
        styles={{
          options: {
            primaryColor: theme.colors.primary,
            zIndex: 10000,
          },
        }}
        callback={handleJoyrideCallback}
      />
      <PeriodSelect
        hideCustom
        hideQuarter
        hideWholeYear
        setValue={setDateFilter}
        onMonthChange={(month, year) => setOverviewDateFilter({month, year})}
      />
      <ResourceSummary noPad>
        <ResourceSummaryItem
          title={translate('Consumption')}
          tooltipText={translate('Sum of consumption')}
          value={certData.consumption.reduce((acc, item) => acc + item.value, 0)}
          color={theme.colors.blue7}
          showFilledProgress
          unit={translate(EnergyUnit.MWh)}
        />
        <ResourceSummaryItem
          title={translate('Contracted')}
          tooltipText={translate('Sum of contracted')}
          value={certData.deliveredPpaProduction.reduce((acc, item) => acc + item.value, 0)}
          color={theme.colors.green2}
          showFilledProgress
          unit={translate('EACs')}
        />
        <ResourceSummaryItem
          title={translate('Purchased certificates')}
          tooltipText={translate('Sum of purchased certificates')}
          value={certData.reservedCertProduction.reduce((acc, item) => acc + item.value, 0)}
          color={theme.colors.green1}
          showFilledProgress
          unit={translate('EACs')}
        />
        <ResourceSummaryItem
          title={translate('Total')}
          tooltipText={translate('Sum of total')}
          value={certData.totalProduction.reduce((acc, item) => acc + item.value, 0)}
          color={theme.colors.green7}
          showFilledProgress
          unit={translate('EACs')}
        />
        <ResourceSummaryItem
          title={translate('Shortfall')}
          tooltipText={translate('Sum of shortfall')}
          value={totalMissing}
          unit={translate('EACs')}
          showFilledProgress
          color={theme.colors.grey2}
        />
        <ResourceSummaryItem
          title={translate('Excess')}
          tooltipText={translate('Sum of excess')}
          value={certData.excess.reduce((acc, item) => acc + item.value, 0)}
          unit={translate('EACs')}
          showFilledProgress
          color={theme.colors.yellow1}
        />
      </ResourceSummary>

      <InnerScroll noRightPad>
        <Box gap={2} pad={{top: 2}} justify="flex-end">
          <Box
            width={35}
            border
            round
            direction="row"
            margin={{bottom: 2, top: 2}}
            pad={{vertical: 1}}
            justify="center"
          >
            <Box gap={3} direction="row">
              <Checkbox
                label="Show Excess"
                checked={filter.showExcess}
                onChange={() => setFilter({...filter, showExcess: !filter.showExcess})}
              />
              <Checkbox
                label="Show Missing"
                checked={filter.showMisisng}
                onChange={() => setFilter({...filter, showMisisng: !filter.showMisisng})}
              />
            </Box>
          </Box>
        </Box>
        {error && (
          <Box justify="center">
            <Heading margin={{top: 4}}>{translate('Failed to load Certificates chart')}</Heading>
          </Box>
        )}
        {!hasData ? (
          <Box justify="center">
            <Heading margin={{top: 4}}>{translate('No data for this interval')}</Heading>
          </Box>
        ) : (
          <>
            <CertificatesChart certData={certData} filter={filter} />
            <Heading margin={2} size="large" style={{textAlign: 'center'}}>
              {translate('Summary')}
            </Heading>
            <Box direction="row" gap={1} justify="center" wrap>
              {excess?.map(({certificateQuantity, technologyKind}, index) => (
                <CertificateSummaryItem
                  title={translate('Excess certificates')}
                  key={index}
                  unit="EAC"
                  action={translate('Offer to market')}
                  quantity={certificateQuantity}
                  tecnology={technologyKind}
                  modal={ModalType.EXCESS_CERTIFICATES}
                  modalValue={technologyKind}
                />
              ))}
              {excess.length === 0 && totalExcess > 0 && (
                <CertificateSummaryItem
                  title={translate('Excess certificates')}
                  unit="EAC"
                  action={translate('Offer to market')}
                  disabled
                  quantity={totalExcess}
                  modal={ModalType.EXCESS_CERTIFICATES}
                />
              )}

              {missing && (
                <CertificateSummaryItem
                  title={translate('Shortfall of energy attribute certificates')}
                  unit="EAC"
                  action={translate('Go to Renewabl Trade')}
                  quantity={totalMissing}
                  to={`/consumer/buy/marketplace/request`}
                  confirmDialogText={translate('You are leaving Renewabl Track to Renewabl Trade')}
                />
              )}
            </Box>
          </>
        )}
      </InnerScroll>
    </Box>
  )
}

export default GreenCertificates
