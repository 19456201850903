import React from 'react'
import {isPartyAdmin} from 'helpers/partyMember'
import {OnboardingStatus} from '../../domain/IParty'
import useLocalization from '../../hooks/useLocalization'
import IRoute from '../../interfaces/IRoute'
import CompanyInformation from './Account/CompanyInformation'
import PersonalProfile from './Account/PersonalProfile'
import Marketplace from './Buy/Marketplace'
import Offers from './Buy/Offers'
import Platform from './index'
import CurrentContract from './Portfolio/CurrentContract'
import Overview from './Portfolio/Overview'
import Sites from './Portfolio/Sites'
import CompanyUsers from './Account/CompanyUsers'
import CertificateDesigner from './Buy/CertificateDesigner'
import MarketplaceOverview from './Admin/MarketplaceOverview'
import {isAdmin} from 'helpers/user'
import SellRequests from './Admin/SellRequests'
import TenderRequest from './Buy/TenderRequest'
import BuyerOnboardingWizard from 'components/Platform/Onboarding/BuyerOnboardingWizard'
import {useAtomValue} from 'jotai'
import {partyAtom, selectedPartyMemberAtom, userAtom} from 'atoms/party'
import ExternalContracts from './Portfolio/ExternalContracts'
import SubscriptionGateway from './Account/Subscription/SubscriptionGateway'
import MarketIntelligence from './Portfolio/MarketIntelligence'

const Consumer = () => {
  const {translate} = useLocalization()
  const party = useAtomValue(partyAtom)
  const selectedPartyMember = useAtomValue(selectedPartyMemberAtom)
  const user = useAtomValue(userAtom)

  const routes: IRoute[] = [
    {
      title: translate('Portfolio'),
      path: '/portfolio',
      children: [
        {
          title: translate('Overview'),
          path: '/portfolio/overview',
          component: Overview,
        },
        {
          title: translate('Signed Contracts'),
          path: '/portfolio/current-contracts',
          component: CurrentContract,
        },
        {
          title: translate('Consumption'),
          path: '/portfolio/sites',
          component: Sites,
        },
        {
          title: translate('External Contracts'),
          path: '/portfolio/external-contracts',
          component: ExternalContracts,
        },
        {
          title: translate('Market Intelligence'),
          path: '/portfolio/market-intelligence',
          component: MarketIntelligence,
        },
        {
          title: translate('Sell requests'),
          path: '/portfolio/sell-requests',
          hidden: true,
          component: SellRequests,
        },
      ],
    },
    {
      title: translate('Onboarding'),
      path: '/onboarding',
      hidden: true,
      children: [
        {
          title: translate('Onboarding wizard'),
          path: '/',
          component: BuyerOnboardingWizard,
          hidden: true,
        },
      ],
    },
    {
      title: translate('Renewabl Trade'),
      path: '/buy',
      confirmationText: translate('You are leaving Renewabl Track to Renewabl Trade'),
      children: [
        {
          title: translate('Renewabl Tender'),
          path: '/buy/marketplace/request',
          component: TenderRequest,
        },
        {
          title: translate('Marketplace'),
          path: '/buy/marketplace/products',
          component: Marketplace,
        },
        {
          title: translate('Offers'),
          path: '/buy/offers',
          component: Offers,
        },
        {
          title: translate('Certificate designer'),
          path: '/buy/certificate-designer',
          component: CertificateDesigner,
          hidden: true,
        },
      ],
    },
    {
      title: translate('Company'),
      path: '/company',
      children: [
        {
          title: translate('Personal profile'),
          path: '/company/personal-profile',
          component: PersonalProfile,
          hidden: true,
        },
        {
          title: translate('General'),
          path: '/company/general',
          component: CompanyInformation,
        },
        {
          title: translate('Subscription'),
          path: '/company/subscription',
          component: SubscriptionGateway,
        },
        {
          title: translate('Members'),
          path: '/company/members',
          component: CompanyUsers,
          hidden: selectedPartyMember && !isPartyAdmin(selectedPartyMember),
        },
      ],
    },
  ]

  if (party && party?.onboardingStatus !== OnboardingStatus.COMPLETED) {
    routes.push({
      title: translate('Onboarding'),
      path: '/onboarding',
      component: BuyerOnboardingWizard,
    })
  }

  if (isAdmin(user)) {
    routes.push({
      title: translate('Admin'),
      path: '/admin',
      children: [
        {
          title: translate('Marketplace overview'),
          path: '/admin/marketplace-overview',
          component: MarketplaceOverview,
        },
        {
          title: translate('Sell requests'),
          path: '/admin/sell-requests',
          component: SellRequests,
        },
      ],
    })
  }

  return <Platform routes={routes} routePrefix="/consumer" />
}

export default Consumer
