import React from 'react'
import useTheme from 'hooks/useTheme'
import styled from 'styled-components'
import DoneIcon from '@mui/icons-material/Done'
import Box from 'components/Box'

const StyledChip = styled('div')<{selected?: boolean; disabled?: boolean}>`
  color: ${props => props.theme.colors.surface};
  background-color: ${props => (props.selected ? props.theme.colors.accent : props.theme.colors.lightGrey)};
  height: 24px;
  min-width: 48px;
  font-size: 12px;
  border-radius: 16px;
  justify-content: center;
  white-space: nowrap;
  align-items: center;
  display: flex;
  padding-left: 10px;
  padding-right: 8px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  box-sizing: border-box;
  font-family: ${props => props.theme.font.secondaryFont};
  font-weight: 200;

  &:hover {
    background-color: ${props => !props.disabled && !props.selected && props.theme.colors.accent};
  }
`

const Chip = ({
  selected,
  label,
  onClick,
  disabled,
}: {
  selected?: boolean
  label: string
  onClick: () => void
  disabled?: boolean
}) => {
  const theme = useTheme()

  return (
    <StyledChip selected={selected} onClick={onClick} disabled={disabled}>
      <Box direction="row" align="center" justify="space-between">
        {selected && (
          <DoneIcon sx={{color: theme.colors.surface, width: 13, height: 13, ml: -0.5, mr: 0.5, mt: '-1px'}} />
        )}
        {label}
      </Box>
    </StyledChip>
  )
}

export default Chip
