import React, {useEffect, useState} from 'react'
import IProduct from 'domain/IProduct'
import {ResourceId} from 'types'
import NotFound from 'components/NotFound'
import {partyAtom} from 'atoms/party'
import {useAtomValue} from 'jotai'
import {rootServiceAtom} from 'atoms/general'

interface IProps extends React.PropsWithChildren {
  id: ResourceId
  component: React.FC<{product: IProduct}>
}

// TODO: remove this wrapper, whenn proper product v2 api will be written
const ProductResolver: React.FC<IProps> = ({id, component: Component, ...props}) => {
  const [loading, setLoading] = useState(true)
  const [product, setProduct] = useState<IProduct>()
  const party = useAtomValue(partyAtom)
  const rootService = useAtomValue(rootServiceAtom)

  useEffect(() => {
    const getProduct = async () => {
      const product = await rootService.contentService.getProductManagedByParty(party.id, id)

      setProduct(product)
      setLoading(false)
    }

    if (party) {
      getProduct()
    }
  }, [party])

  if (id && !product && !loading && party) {
    return <NotFound />
  }

  return <Component product={product} {...props} />
}

export default ProductResolver
