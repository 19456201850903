import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Text from 'components/Text'
import useFormContext from 'hooks/useFormContext'
import styled from 'styled-components'
import useLocalization from 'hooks/useLocalization'
import useProfile from 'hooks/useProfile'
import Form from 'components/Form'
import FormInput from 'components/Form/FormInput'
import FormSubmitButton from 'components/Form/FormSubmitButton'
import Heading from 'components/Heading'
import Input from 'components/Input'
import FullscreenLoader from 'components/FullscreenLoader'
import {useAtomValue} from 'jotai'
import {rootServiceAtom} from 'atoms/general'
import useAlerts from 'hooks/useAlerts'
import usePartyActions from 'hooks/usePartyActions'

const StyledButton = styled(Button)`
  margin-top: 20px;
`

const PHONE_NUM_FORM_FIELD_NAME = 'phoneNumber'

const PersonalInformationForm = ({setShowPhoneVerificationModal}) => {
  const {translate} = useLocalization()
  const {user} = useProfile()
  const formContext = useFormContext()
  const isPhoneNumberFieldDirty = formContext.formState.dirtyFields.has(PHONE_NUM_FORM_FIELD_NAME)

  return (
    <Box direction="column" justify="space-between" height="100%">
      <Box>
        <Heading size="small" margin={{bottom: 2}}>
          {translate('Personal information')}
        </Heading>
        <FormInput
          data-cy="first-name-input"
          label={translate('First name')}
          name="firstName"
          defaultValue={user.firstName}
          required
        />
        <FormInput
          data-cy="last-name-input"
          label={translate('Surname')}
          name="lastName"
          defaultValue={user.lastName}
          required
        />
        <Input
          data-cy="email-input"
          label={translate('Email address')}
          name="email"
          disabled
          defaultValue={user.email}
        />

        <Box direction="row" gap={5}>
          <Box grow>
            <FormInput
              label={translate('Phone number')}
              name={PHONE_NUM_FORM_FIELD_NAME}
              defaultValue={user.phoneNumber}
              placeholder="e.g. +XXXXXXXXXX"
              pattern={/^\+[1-9]\d{1,14}$/}
            />
          </Box>
          {user.phoneNumber && !user.phoneNumberVerified && (
            <StyledButton
              margin={{bottom: 2, top: 3}}
              type="button"
              onClick={() => setShowPhoneVerificationModal(true)}
            >
              {translate('Verify Phone')}
            </StyledButton>
          )}
        </Box>
        {user.phoneNumber && !user.phoneNumberVerified && (
          <Box>
            <Text margin={{bottom: 2}}>{translate('Your phone number is not verified') + '.'}</Text>
          </Box>
        )}
        {user.phoneNumber && user.phoneNumberVerified && !isPhoneNumberFieldDirty && (
          <Text margin={{bottom: 2}}>{translate('Phone number verified')}.</Text>
        )}
      </Box>

      <FormSubmitButton data-cy="save-button" margin={{top: 1}}>
        {translate('Save')}
      </FormSubmitButton>
    </Box>
  )
}

const PersonalInformationFormWrap = ({setShowPhoneVerificationModal}) => {
  const {addSuccess, addError} = useAlerts()
  const {translate} = useLocalization()
  const {refreshParties} = usePartyActions()
  const {isLoaded} = useProfile()
  const rootService = useAtomValue(rootServiceAtom)
  const {v2ContentService} = rootService

  const handleSubmit = async formData => {
    try {
      await v2ContentService.updateUserProfile(formData)
      await refreshParties()
      addSuccess(translate('Successfully saved profile') + '.')
    } catch (ex) {
      console.error(ex)
      addError(translate('Failed to update profile.'), ex?.correlationId, ex?.message)
    }
  }

  if (!isLoaded) {
    return <FullscreenLoader />
  }

  return (
    <Form onSubmit={handleSubmit} submissionFeedback={null} fillHeight>
      <PersonalInformationForm setShowPhoneVerificationModal={setShowPhoneVerificationModal} />
    </Form>
  )
}

export default PersonalInformationFormWrap
