import IAdminCreatePartyMember from 'domain/IAdminCreateUser'
import {TextDocumentType} from 'domain/IDocumentType'
import {IPartyMemberUpdateRequest} from 'domain/IPartyMemberUpdateRequest'
import {IProposalDetailsBody} from 'domain/IProposalDetails'
import IPurchaseContract from 'domain/IPurchaseContract'
import ITextDocument from 'domain/ITextDocument'
import IAttachment from 'domain/IAttachment'
import IOffer from 'domain/IOffer'
import IPartyMember from 'domain/IPartyMember'
import {IPasswordFromInvite} from 'domain/IPasswordFromInvite'
import IProduct from 'domain/IProduct'
import {IUserProfile} from 'domain/IUserProfile'

export enum Interval {
  HOURLY = 'hourly',
  DAILY = 'daily',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export default interface IContentService {
  getTextDocument(documentType: TextDocumentType, locale: string): Promise<ITextDocument>

  sendContractVerificationCode(contractId: number)

  confirmContractVerificationCode(contractId: number, code: string)

  confirmUserPhoneNumber(code: string): Promise<void>

  sendUserPhoneVerification(phone: string): Promise<void>

  getProductManagedByParty(partyId: any, id: any): Promise<IProduct>

  editCertificateOffer(partyId: any, offerId: any, payload: IProposalDetailsBody): Promise<IOffer>

  getContractContractTemplatePdf(contractId: any, templateId: any, partyId: any): Promise<Blob>

  getContractContractTemplates(contractId: any, partyId: any): Promise<any>

  getContractHtml(contractId: any): Promise<string>

  getPartyMembers(asPartyId: any): Promise<IPartyMember[]>

  getPartyMember(asPartyId: number, partyMemberId: number): Promise<IPartyMember>

  setPasswordFromInvite(accessCode: string, password: IPasswordFromInvite): Promise<void>

  getUserFromInvite(accessCode: string): Promise<IUserProfile>

  getAttachmentURL(attachment: IAttachment): string

  getSalesContracts(partyId: any): Promise<IPurchaseContract[]>
}
