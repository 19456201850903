import {AuthorityType} from 'domain/AuthorityType'
import {IManagedUser, ManagedUserStatus} from 'domain/IManagedUser'
import ITheme from 'theme/ITheme'
import IParty, {PartyRole} from '../domain/IParty'
import ITranslate from '../interfaces/ITranslate'
import {IUserProfile} from 'domain/IUserProfile'
import env from '@beam-australia/react-env'

export function getAccountType(party: IParty, translate: ITranslate, theme?: ITheme): string {
  switch (party?.partyRole) {
    case PartyRole.BUYER:
      return translate('Buyer')
    //TODO: remove this
    case PartyRole.PRODUCER:
      return translate('Producer')
    case PartyRole.BROKER:
      // Broker role is now considered seller role, and old seller role will be removed
      return translate('Seller')
    default:
      return '-'
  }
}

export function getFullName(user: IUserProfile): string {
  return `${user?.firstName} ${user?.lastName}`
}

export function isAdmin(user: IUserProfile): boolean {
  return user?.roles?.includes(AuthorityType.ROLE_ADMIN)
}

export function isRenewablEmail(user: IUserProfile): boolean {
  return user?.email?.endsWith('@renewabl.com')
}

export function showAdminMessage(user: IUserProfile): boolean {
  return isAdmin(user) || isRenewablEmail(user)
}

export const getManagedStatus = (user: IUserProfile): ManagedUserStatus => {
  let status: ManagedUserStatus
  if (!user.activated) {
    status = ManagedUserStatus.Invited
  } else {
    status = user.enabled ? ManagedUserStatus.Active : ManagedUserStatus.Suspended
  }

  return status
}

export function toManagedUser(user: IUserProfile): IManagedUser {
  const status = getManagedStatus(user)
  return {...user, status}
}

export function initializeHelpCrunchUser(user: IUserProfile, party: IParty) {
  if (env('HIDE_HELPCRUNCH') === 'false') {
    window.HelpCrunch('init', window.helpcrunchSettings.organization, {
      appId: env('HELPCRUNCH_ID'),
      user: {
        user_id: user.id,
        email: user.email,
        name: user.firstName + ' ' + user.lastName,
        phone: user.phoneNumber,
        custom_data: {
          party_name: party?.name,
          onboarding_status: party?.onboardingStatus,
          platform_id: user.id,
        },
      },
    })

    window.HelpCrunch('showChatWidget')
  }
}
