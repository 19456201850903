import React from 'react'
import Page from 'components/Page'
import useLocalization from 'hooks/useLocalization'
import NoContract from './index'
import {getPartyLink} from 'helpers/party'
import {useAtomValue} from 'jotai'
import {partyAtom} from 'atoms/party'

const NoSignedContract: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const party = useAtomValue(partyAtom)

  return (
    <Page
      title={translate('You don’t have any active contract at the moment')}
      description={translate('You can create the contract in contract designer')}
    >
      <NoContract
        buttonText={translate('Go to marketplace')}
        buttonLink={`/${getPartyLink(party?.partyRole)}/buy/marketplace/products`}
      />
    </Page>
  )
}

export default NoSignedContract
