import IAdminCreatePartyMember from 'domain/IAdminCreateUser'
import {TextDocumentType} from 'domain/IDocumentType'
import {IPartyMemberUpdateRequest} from 'domain/IPartyMemberUpdateRequest'
import {IProposalDetailsBody} from 'domain/IProposalDetails'
import IPurchaseContract from 'domain/IPurchaseContract'
import ITextDocument from 'domain/ITextDocument'
import IAttachment from '../domain/IAttachment'
import IContractType from '../domain/IContractType'
import IOffer, {IOfferInvite} from '../domain/IOffer'
import IParty, {PartyRole} from '../domain/IParty'
import IPartyMember from '../domain/IPartyMember'
import {IPasswordFromInvite} from '../domain/IPasswordFromInvite'
import IProduct from '../domain/IProduct'
import IContentService from './IContentService'
import IHttpClient from './IHttpClient'
import {IUserProfile} from 'domain/IUserProfile'

export default class LederhosenContentService implements IContentService {
  private readonly httpClient: IHttpClient

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient
  }

  public async getTextDocument(documentType: TextDocumentType, locale: string = 'en'): Promise<ITextDocument> {
    const {data} = await this.httpClient.get<ITextDocument>(`/public/text-document`, {params: {documentType, locale}})

    return data
  }

  public async getProductManagedByParty(partyId: any, id: any): Promise<IProduct> {
    const {data} = await this.httpClient.get<IProduct>(`/public/parties/${partyId}/managed-products/${id}`)

    return data
  }

  public async editCertificateOffer(partyId: any, offerId: any, body: IProposalDetailsBody): Promise<IOffer> {
    const {data} = await this.httpClient.put<IOffer>(
      `/public/parties/${partyId}/offers/${offerId}/purchase-designer`,
      body,
    )

    return data
  }

  public async createProduct(partyId: any, product: IProduct): Promise<IProduct> {
    const {data} = await this.httpClient.post<IProduct>(`/public/parties/${partyId}/products`, product)

    return data
  }

  public async getContractContractTemplatePdf(contractId: any, templateId: any, partyId: any): Promise<Blob> {
    const {data} = await this.httpClient.get(
      `/public/${partyId}/contract-templates/${templateId}/contracts/${contractId}`,
      {
        headers: {
          accept: 'application/pdf',
        },
        responseType: 'blob',
      },
    )

    return new Blob([data])
  }

  public async getContractContractTemplates(contractId: any, partyId: any): Promise<any> {
    const {data} = await this.httpClient.get<IContractType>(
      `/public/${partyId}/contract-templates/contracts/${contractId}`,
    )

    return data
  }

  public async getContractHtml(contractId: any): Promise<string> {
    const {data} = await this.httpClient.get(`/public/contracts/${contractId}`, {
      headers: {
        accept: 'text/html',
      },
    })

    return data
  }

  public async confirmUserPhoneNumber(code: string): Promise<void> {
    const {data} = await this.httpClient.post<void>(`/public/phone-verification/confirm?verification_code=${code}`)

    return data
  }

  public async sendUserPhoneVerification(phone: string): Promise<void> {
    const {data} = await this.httpClient.post<void>(`/public/phone-verification`, {phone})

    return data
  }

  public async sendContractVerificationCode(contractId: number): Promise<void> {
    const {data} = await this.httpClient.post<void>(`/public/contract-verification?contract=${contractId}`)

    return data
  }

  public async confirmContractVerificationCode(contractId: number, code: string): Promise<void> {
    const {data} = await this.httpClient.post<void>(
      `/public/contract-verification/confirm?contract=${contractId}&code=${code}`,
    )

    return data
  }

  public async getPartyMembers(asPartyId: number): Promise<IPartyMember[]> {
    const {data} = await this.httpClient.get<IPartyMember[]>(`/public/parties/${asPartyId}/members`)

    return data
  }

  public async getUserFromInvite(accessCode: string): Promise<IUserProfile> {
    const {data} = await this.httpClient.get(`/public/user-invites/${accessCode}`)

    return data as IUserProfile
  }

  public async setPasswordFromInvite(accessCode: string, password: IPasswordFromInvite): Promise<void> {
    await this.httpClient.post(`/public/user-invites/${accessCode}/confirm`, password)
  }

  public async getPartyMember(asPartyId: number, partyMemberId: number): Promise<IPartyMember> {
    const {data} = await this.httpClient.get(`/public/parties/${asPartyId}/members/${partyMemberId}`)

    return data
  }

  public getAttachmentURL(attachment: IAttachment): string {
    if (!attachment) {
      return ''
    }

    return `${this.httpClient.defaults.baseURL}/public/attachments/download/${attachment.id}-${attachment.fileName}`
  }

  public async getSalesContracts(partyId: any): Promise<IPurchaseContract[]> {
    const {data} = await this.httpClient.get<IPurchaseContract[]>(`/public/parties/${partyId}/sales-contracts`)

    return data
  }
}
