import React, {ReactElement} from 'react'
import {hoveredKeyAtom, setHoveredKeyAtom} from 'atoms/map'
import {useAtomValue, useSetAtom} from 'jotai'

interface IProps extends React.PropsWithChildren {
  hoverKey: string
}

const ConnectedHoverable: React.FC<IProps> = ({hoverKey, children}) => {
  const hoveredItem = useAtomValue(hoveredKeyAtom)
  const setHoveredItem = useSetAtom(setHoveredKeyAtom)
  const hasMouseOver = hoverKey === hoveredItem

  return (
    <>
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
          return null
        }

        return React.cloneElement(child as ReactElement<any>, {
          hasMouseOver,
          onMouseOver: () => setHoveredItem(hoverKey),
          onMouseOut: () => setHoveredItem(null),
        })
      })}
    </>
  )
}

export default ConnectedHoverable
