import {IUserProfile} from 'domain/IUserProfile'
import IParty from 'domain/IParty'
import IPartyMember from 'domain/IPartyMember'
import {userAtom, partyAtom, appLoadedAtom, selectedPartyMemberAtom} from 'atoms/party'
import {useAtomValue} from 'jotai'

interface IProfile {
  user: IUserProfile
  party: IParty
  partyMembership: IPartyMember
  isLoaded: boolean
}

const useProfile = (): IProfile => {
  const user = useAtomValue(userAtom)
  const party = useAtomValue(partyAtom)
  const partyMembership = useAtomValue(selectedPartyMemberAtom)
  const isLoaded = useAtomValue(appLoadedAtom)

  return {user, party, partyMembership, isLoaded}
}

export default useProfile
