import Auth from '@aws-amplify/auth'
import Amplify from '@aws-amplify/core'
import ValidationError from '../exceptions/ValidationError'
import IAuthenticationService from './IAuthenticationService'
import {IUserProfile} from 'domain/IUserProfile'

interface CognitoConfig {
  region: string
  userPoolId: string
  userPoolWebClientId: string
}

export default class CognitoAuthenticationService implements IAuthenticationService {
  public constructor({region, userPoolId, userPoolWebClientId}: CognitoConfig, endpoint: string) {
    Amplify.configure({
      Auth: {
        region,
        userPoolId,
        userPoolWebClientId,
        endpoint,
      },
    })
  }

  public async signIn(email: string, password: string): Promise<void> {
    await Auth.signIn(email, password)
  }

  public async getToken(): Promise<string> {
    try {
      const session = await Auth.currentSession()

      return session.getIdToken().getJwtToken()
    } catch (err) {
      return null
    }
  }

  public async refreshToken(): Promise<string> {
    const cognitoUser = await Auth.currentAuthenticatedUser({bypassCache: true})
    const session = cognitoUser.getSignInUserSession()

    return session.getIdToken().getJwtToken()
  }

  public async signOut(): Promise<void> {
    await Auth.signOut()
  }

  public async updateInfo(user: IUserProfile): Promise<void> {
    const {firstName, lastName, phoneNumber} = user

    await Auth.updateUserAttributes(await Auth.currentAuthenticatedUser(), {
      given_name: firstName,
      family_name: lastName,
      phone_number: phoneNumber,
    })

    await Auth.currentAuthenticatedUser({bypassCache: true})
  }

  public async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    try {
      await Auth.changePassword(await Auth.currentAuthenticatedUser(), oldPassword, newPassword)
    } catch (error) {
      if (error.code === 'NotAuthorizedException') {
        throw new ValidationError('Incorrect current password', [
          {
            key: 'oldPassword',
            message: 'Incorrect password',
          },
        ])
      }

      throw error
    }
  }

  public async forgotPassword(email: string): Promise<void> {
    await Auth.forgotPassword(email)
  }

  public async forgotPasswordSubmit(email: string, code: string, password: string): Promise<void> {
    await Auth.forgotPasswordSubmit(email, code, password)
  }
}
