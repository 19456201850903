import {atom, PrimitiveAtom} from 'jotai'
import RootService from 'services'
import {Interval} from 'services/IContentService'

export const rootServiceAtom = atom<RootService>(RootService.create()) as PrimitiveAtom<RootService>
export const intervalAtom = atom(Interval.HOURLY)
intervalAtom.onMount = setAtom => {
  return () => {
    setAtom(Interval.HOURLY)
  }
}

export const googleApiAtom = atom<PrimitiveAtom<any>>()

export const alertsAtom = atom([])
