import React, {useState} from 'react'
import useLocalization from 'hooks/useLocalization'
import Form from 'components/Form'
import Page from 'components/Page'
import FormSubmitButton from 'components/Form/FormSubmitButton'
import InnerScroll from 'components/InnerScroll'
import Box from 'components/Box'
import {useAtomValue} from 'jotai'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom} from 'atoms/party'
import useAlerts from 'hooks/useAlerts'
import usePartyActions from 'hooks/usePartyActions'
import FormInput from 'components/Form/FormInput'
import FormEmail from 'components/Form/FormEmail'
import LocationSearch, {LocationBox} from 'components/Platform/Portfolio/Site/LocationSearch'
import Label from 'components/Label'
import Heading from 'components/Heading'
import Button from 'components/Button'
import {BaseUserKind} from 'services/V2ApiErrorMapper'
import FormVatCodeInput from 'components/Form/FormVatCodeInput'

interface IProps extends React.PropsWithChildren {}

const AddExternalContractPage: React.FC<IProps> = () => {
  const {translate} = useLocalization()
  const [createdPartyId, setCreatedPartyId] = useState(null)
  const disabled = !!createdPartyId
  const {switchParty, refreshParties} = usePartyActions()
  const {addError, addSuccess} = useAlerts()
  const party = useAtomValue(partyAtom)
  const rootService = useAtomValue(rootServiceAtom)

  const handleSubmit = async (data, {setError}) => {
    const {location, ...rest} = data
    const payload = {...rest, placeId: location.placeId}

    try {
      const response = await rootService.v2ContentService.addSellerCustomer(party.id, payload)
      setCreatedPartyId(response.id)

      addSuccess(translate('The customer was created. You can access the company to upload contracts.'))
      await refreshParties()
    } catch (ex) {
      const kind = ex?.error?.kind

      if (kind === BaseUserKind.INVALID_VAT_CODE) {
        setError('vatCode', 'vatCode', translate(ex?.message))
      } else {
        addError(translate('Failed to create customer'), ex?.correlationId, ex?.message)
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit} fillHeight submissionFeedback={null}>
      <Page
        title={translate('Add new customer')}
        noDivider
        description={translate('Invite customer to the platform')}
        corner={
          <Box gap={2}>
            {disabled && (
              <Button
                onClick={() => switchParty(createdPartyId, 'portfolio/external-contracts/add')}
                data-cy="upload-contract-button"
                variant="secondary"
              >
                {translate('Upload contract')}
              </Button>
            )}
            <FormSubmitButton disabled={disabled}>{translate('Save')}</FormSubmitButton>
          </Box>
        }
      >
        <InnerScroll noRightPad>
          <Box pad={2} border round>
            <Heading pad={{bottom: 2}}>{translate('Corporate account')}</Heading>
            <FormInput
              name="name"
              label={translate('Company name')}
              required
              data-cy="name"
              disabled={disabled}
              defaultValue=""
            />
            <FormInput
              name="registrationCode"
              label={translate('Company reg. number')}
              required
              data-cy="registrationCode"
              disabled={disabled}
              defaultValue=""
            />

            <LocationBox data-cy="location-select">
              <Label text={translate('Location')}>
                <LocationSearch location={null} variant="primary" isDisabled={disabled} required />
              </Label>
            </LocationBox>

            <FormVatCodeInput name="vatCode" disabled={disabled} />

            <FormInput
              name="contactFirstName"
              label={translate('Contact first name')}
              required
              data-cy="contactFirstName"
              disabled={disabled}
              defaultValue=""
            />
            <FormInput
              name="contactLastName"
              label={translate('Contact last name')}
              required
              data-cy="contactLastName"
              disabled={disabled}
              defaultValue=""
            />
            <FormEmail
              name="contactEmail"
              label={translate('Contact email')}
              required
              data-cy="contactEmail"
              disabled={disabled}
              defaultValue=""
            />
            <FormInput
              name="externalRef"
              label={translate('External reference')}
              data-cy="externalRef"
              disabled={disabled}
              required
              defaultValue=""
            />
          </Box>
        </InnerScroll>
      </Page>
    </Form>
  )
}

export default AddExternalContractPage
