import React from 'react'
import Heading from 'components/Heading'
import useLocalization from 'hooks/useLocalization'
import Form from 'components/Form'
import FormInput from 'components/Form/FormInput'
import FormSubmitButton from 'components/Form/FormSubmitButton'
import useServices from 'hooks/useServices'
import Box from 'components/Box'
import FormPasswordInput from 'components/Form/FormPasswordInput'

const ChangePasswordForm = () => {
  const {translate} = useLocalization()
  const {authenticationService} = useServices()

  const handleSubmit = async ({oldPassword, newPassword}) => {
    await authenticationService.changePassword(oldPassword, newPassword)
  }

  return (
    <Form onSubmit={handleSubmit} fillHeight>
      <Box direction="column" height="100%" justify="space-between">
        <Box>
          <Heading size="small" margin={{bottom: 2}}>
            {translate('Change password')}
          </Heading>
          <FormInput type="password" required label={translate('Current password')} name="oldPassword" minLength={6} />
          <FormPasswordInput name="newPassword" />
          <FormInput
            type="password"
            required
            label={translate('Repeat new password')}
            name="newPasswordRepeat"
            validate={(value, getValues) => value === getValues().newPassword || translate('Passwords do not match')}
          />
        </Box>
        <FormSubmitButton margin={{top: 1}}>{translate('Save')}</FormSubmitButton>
      </Box>
    </Form>
  )
}

export default ChangePasswordForm
