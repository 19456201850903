import {IUserProfile} from 'domain/IUserProfile'
import IAuthenticationService from './IAuthenticationService'
import LocalStorageDataService from './LocalStorageDataService'

export default class LocalAuthenticationService implements IAuthenticationService {
  private static EMAIL_CODE = '123456'

  private readonly authenticated = new LocalStorageDataService<{
    id: any
    email: string
  }>('authenticated')
  private readonly users = new LocalStorageDataService<IUserProfile>('users')

  public async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    const [au] = this.authenticated.getItems()
    const user = this.users.getItems().find(u => u.email === au?.email)

    this.users.updateItem(user)
  }

  public async confirmSignUp(email: string, code: string): Promise<void> {
    this.verifyEmailCode(email, code)

    this.users.getItems()

    const user = this.users.getItems().find(u => u.email === email)
    user.emailVerified = true

    this.users.updateItem(user)
  }

  public async forgotPassword(email: string): Promise<void> {
    if (!this.users.getItems().find(u => u.email === email)) {
      throw new Error('User does not exist')
    }
  }

  public async forgotPasswordSubmit(email: string, code: string, password: string): Promise<void> {
    this.verifyEmailCode(email, code)

    const users = this.users.getItems()
    const user = users.find(u => u.email === email)

    if (!user) {
      throw new Error('Invalid user')
    }

    this.users.setItems(users)
  }

  public async getToken(): Promise<string> {
    const [au] = this.authenticated.getItems()
    const user = this.users.getItems().find(u => u.email === au?.email)

    if (!au) {
      return null
    }

    return user ? 'true' : null
  }

  public async refreshToken(): Promise<string> {
    return null
  }

  public async resendSignUp(email: string): Promise<void> {
    // Do nothing
  }

  public async signIn(email: string, password: string): Promise<void> {
    const users = this.users.getItems()

    if (!users.find(u => u.email === email)) {
      throw new Error('Invalid username or password')
    }

    this.authenticated.setItems([])
    this.authenticated.addItem({email})
  }

  public async signOut(): Promise<void> {
    this.authenticated.setItems([])
  }

  public async updateInfo(user: IUserProfile): Promise<void> {
    this.users.updateItem(user)
  }

  private verifyEmailCode(email: string, code: string) {
    if (code !== LocalAuthenticationService.EMAIL_CODE) {
      throw new Error('Invalid code')
    }
  }
}
