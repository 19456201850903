import React from 'react'
import styled, {ThemeProvider as ScThemeProvider} from 'styled-components'
import ITheme from '../theme/ITheme'
import {useAtomValue} from 'jotai'
import {partyAtom} from 'atoms/party'
import {getTheme} from 'theme'

const Main = styled.div<{theme?: ITheme}>`
  color: ${props => (props.theme as ITheme).colors.secondary};
  font-family: ${props => (props.theme as ITheme).font.primaryFont}, sans-serif;
  font-size: 14px;
  line-height: 20px;
  background-color: ${props => (props.theme as ITheme).colors.common.white};
  min-height: 100vh;

  button {
    outline: none;
  }
`

const ThemeProvider: React.FC<React.PropsWithChildren<{theme: ITheme}>> = ({children, theme}) => {
  const party = useAtomValue(partyAtom)
  const partyTheme = getTheme(party?.theme?.name, party?.theme?.logoUrl)

  return (
    <ScThemeProvider theme={partyTheme}>
      <Main>{children}</Main>
    </ScThemeProvider>
  )
}

export default ThemeProvider
