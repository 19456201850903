import React from 'react'
import Loader, {ILoaderProps} from 'components/Loader'
import Box from 'components/Box'
import Text from 'components/Text'

const CenteredText = ({text}: {text: string}) => (
  <Box justify="center" margin={{vertical: 3}}>
    <Text size="large">{text}</Text>
  </Box>
)

export default CenteredText
