import React, {useEffect, useMemo} from 'react'
import {mapDataAtom, rawGreenTarifAtom} from 'atoms/marketIntelligence'
import Box from 'components/Box'
import Table, {TableValue} from 'components/Table'
import Text from 'components/Text'
import {Country} from 'domain/ILocation'
import {IGreenTarifInterval, IRawGreenTarif} from 'domain/IMarketIntelligence'
import {getHourlyInterval} from 'helpers/date'
import {getHumanReadableCountry} from 'helpers/location'
import {getPriceUnitSymbol, getSlashEnergyUnit} from 'helpers/price'
import useLocalization from 'hooks/useLocalization'
import useTheme from 'hooks/useTheme'
import {useAtomValue, useSetAtom} from 'jotai'
import Flag from 'react-world-flags'
import {calculateRawAvgPricesByCountry} from './GreenTarif'
import {PriceUnit} from 'domain/IPrice'
import {Tooltip} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const RawGreenTarifTable = ({showSpotPrices, showEacPrices}: {showSpotPrices: boolean; showEacPrices: boolean}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const setMapData = useSetAtom(mapDataAtom)
  const {data: greenTarif, loading} = useAtomValue(rawGreenTarifAtom)

  const countriesWithPriceUnit =
    useMemo(
      () =>
        greenTarif?.timestamps[0].countryTariffs.reduce((acc, priceObj) => {
          acc[priceObj.country] = priceObj.greenTariff.priceUnit
          return acc
        }, {}),
      [greenTarif],
    ) || {}

  const allCountries = useMemo(() => {
    const availableCountries = Object.keys(countriesWithPriceUnit) || []
    const missingCountries = greenTarif?.failedCountries || []
    return [...availableCountries, ...missingCountries]
  }, [countriesWithPriceUnit, greenTarif?.failedCountries])
  const avgPricesByCountry = useMemo(() => calculateRawAvgPricesByCountry(greenTarif?.timestamps || []), [greenTarif])

  useEffect(() => {
    if (!loading) {
      setMapData(
        Object.entries(avgPricesByCountry).map(([country, price]) => ({
          price,
          country,
          priceUnit: countriesWithPriceUnit[country],
        })),
      )
    }
  }, [greenTarif, loading])

  return (
    <Table<IGreenTarifInterval>
      tableName={translate('Spot price')}
      highlightOnHover
      isLoading={loading}
      data={greenTarif?.timestamps}
      columns={[
        {
          title: translate('Hour'),
          accessor: interval => interval.timestamp,
          render: (date: string) => (
            <Text size="medium" nowrap font={theme.font.numbers}>
              {getHourlyInterval(date)}
            </Text>
          ),
        },
        ...allCountries.map((country: Country) => ({
          sortable: false,
          title: (
            <Box direction="row" align="center" gap={1}>
              <Flag height={12} width={20} code={country} />
              <Text style={{lineHeight: '100%'}} size="msmall" font={theme.font.headings} margin={{top: 0.5}}>
                {getHumanReadableCountry(country, translate)}
              </Text>
              {greenTarif?.failedCountries?.includes(country) && (
                <Tooltip title={translate('No data available for selected timeperiod')}>
                  <div>
                    <Box align="center">
                      <InfoOutlinedIcon color="error" sx={{height: '16px', width: '16px'}} />
                    </Box>
                  </div>
                </Tooltip>
              )}
            </Box>
          ),
          accessor: interval => interval.countryTariffs.find(p => p.country === country),
          render: item => (
            <Box direction="column" gap={1} margin={{vertical: 1}} style={{minWidth: '130px'}}>
              <TableValue
                bold={showEacPrices || showSpotPrices}
                nowrap
                prefix={item?.greenTariff?.price ? `${getPriceUnitSymbol(item?.greenTariff?.priceUnit)}` : ''}
                value={item?.greenTariff?.price}
                unit={item?.greenTariff?.priceUnit ? getSlashEnergyUnit(item?.greenTariff?.priceUnit) : ''}
              />
              {showSpotPrices && item?.spotPrice?.price && (
                <TableValue
                  nowrap
                  prefix={item?.spotPrice?.price ? `${getPriceUnitSymbol(item?.spotPrice?.priceUnit)}` : ''}
                  value={item?.spotPrice?.price}
                  unit={`${item?.spotPrice?.priceUnit ? getSlashEnergyUnit(item?.spotPrice?.priceUnit) : ''} SPOT`}
                />
              )}
              {showEacPrices && item?.certPrice?.price && (
                <TableValue
                  nowrap
                  prefix={item?.certPrice?.price ? `${getPriceUnitSymbol(item?.certPrice?.priceUnit)}` : ''}
                  value={item?.certPrice?.price}
                  unit={`${item?.certPrice?.priceUnit ? getSlashEnergyUnit(item?.certPrice?.priceUnit) : ''} EAC`}
                />
              )}
            </Box>
          ),
        })),
      ]}
    />
  )
}

export default RawGreenTarifTable
