import {mapDataAtom} from 'atoms/marketIntelligence'
import Box from 'components/Box'
import {periodAtom, PeriodType} from 'components/PeriodSelect/periodSelect'
import Switch from 'components/Switch'
import {IAggrGreenTarifInterval, IGreenTarifInterval} from 'domain/IMarketIntelligence'
import {useAtomValue, useSetAtom} from 'jotai'
import React, {useEffect, useState} from 'react'
import AggrGreenTarifTable from './AggrGreenTarifTable'
import RawGreenTarifTable from './RawGreenTarifTable'

export const calculateRawAvgPricesByCountry = (intervals: IGreenTarifInterval[]) => {
  const total = intervals.length
  const totalNotEmptyByCountry: Record<string, number> = {}

  return intervals.reduce<Record<string, number>>((acc, interval, index) => {
    interval.countryTariffs?.forEach(p => {
      if (p.greenTariff.price) {
        totalNotEmptyByCountry[p.country] = (totalNotEmptyByCountry[p.country] || 0) + 1
        acc[p.country] = (acc[p.country] || 0) + +p.greenTariff.price
      }

      if (index === total - 1) {
        acc[p.country] = acc[p.country] / totalNotEmptyByCountry[p.country]
      }
    })
    return acc
  }, {})
}

export const calculateAggrAvgPricesByCountry = (intervals: IAggrGreenTarifInterval[]) => {
  const total = intervals.length
  const totalNotEmptyByCountry: Record<string, number> = {}

  return intervals.reduce<Record<string, number>>((acc, interval, index) => {
    interval.prices?.forEach(p => {
      if (p.greenTariff.price) {
        totalNotEmptyByCountry[p.country] = (totalNotEmptyByCountry[p.country] || 0) + 1
        acc[p.country] = (acc[p.country] || 0) + +p.greenTariff.price
      }

      if (index === total - 1) {
        acc[p.country] = acc[p.country] / totalNotEmptyByCountry[p.country]
      }
    })
    return acc
  }, {})
}
const GreenTarif: React.FC = () => {
  const period = useAtomValue(periodAtom)
  const setMapData = useSetAtom(mapDataAtom)

  const [state, setState] = useState({
    spot: false,
    eac: false,
  })

  useEffect(() => {
    return () => setMapData([])
  }, [])

  return (
    <Box>
      <Box direction="row" gap={1} pad={{left: 2}}>
        <Switch
          checked={state.spot}
          onChange={() => setState({...state, spot: !state.spot})}
          label="Show spot prices"
        />
        <Switch checked={state.eac} onChange={() => setState({...state, eac: !state.eac})} label="Show EAC prices" />
      </Box>
      {period.period === PeriodType.QUICK_LINK ? (
        <RawGreenTarifTable showSpotPrices={state.spot} showEacPrices={state.eac} />
      ) : (
        <AggrGreenTarifTable showSpotPrices={state.spot} showEacPrices={state.eac} />
      )}
    </Box>
  )
}

export default GreenTarif
