import {Props as ReactSelectProps} from 'react-select'
import {withLabel} from './Label'
import React, {useState} from 'react'
import {ReactSelect} from './MultiSelect'
import useLocalization from '../hooks/useLocalization'
import styled from 'styled-components'
import ITheme from 'theme/ITheme'
import Box from 'components/Box'

export interface IProps extends React.PropsWithChildren, ReactSelectProps {
  error?: string
  suggestAt?: number
  color?: string
  onInputChange?: (term: string) => void
  isSearch?: boolean
}

const ErrorMessage = styled.div`
  margin-top: -16px;
  display: block;
  padding: 0 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
  color: ${props => (props.theme as ITheme).colors.error};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`

function getValue(value, options, isSearch, placeholder, translate) {
  if (!value && placeholder) {
    return
  }

  if (!value && !placeholder) {
    return {label: translate(isSearch ? 'Search..' : 'Select...'), value: ''}
  }

  return (options as any).find(o => o.value.toString() === value.toString())
}

const AutocompleteSelect: React.FC<IProps> = React.forwardRef(
  (
    {value, options, onChange, suggestAt, onInputChange, isSearch, error, customError, placeholder, ...props},
    ref: React.Ref<any>,
  ) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false)
    const {translate} = useLocalization()

    return (
      <>
        <ReactSelect
          hideIndicators
          ref={ref}
          placeholder={placeholder && placeholder}
          value={getValue(value, options, isSearch, placeholder, translate)}
          onChange={(newValue: any, action) => {
            onChange && onChange(newValue.value, action)
          }}
          menuIsOpen={suggestAt > 0 ? menuOpen : undefined}
          options={options}
          onInputChange={term => {
            onInputChange && onInputChange(term)
            setMenuOpen(term.length >= suggestAt)
          }}
          error={error}
          {...props}
        />
        <Box direction="column" gap={2}>
          {isSearch && error && <ErrorMessage data-cy="input-error">This field is required</ErrorMessage>}
          {isSearch && customError && <ErrorMessage data-cy="input-error">{customError}</ErrorMessage>}
        </Box>
      </>
    )
  },
)

AutocompleteSelect.defaultProps = {
  suggestAt: 3,
}

AutocompleteSelect.displayName = 'AutocompleteSelect'

export default withLabel<IProps>(AutocompleteSelect)
