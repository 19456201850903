import React from 'react'
import {rootServiceAtom} from 'atoms/general'
import {StyledReloadingButton} from 'components/Platform/Onboarding/Layout/FormComponents'
import QuickSignInForm from 'components/Platform/Onboarding/QuickSignInForm'
import IUserInvite from 'domain/IUserInvite'
import {useAtomValue} from 'jotai'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import Box from 'components/Box'
import Grid from 'components/Grid'
import InnerScroll from 'components/InnerScroll'
import Loader from 'components/Loader'
import Page from 'components/Platform/Onboarding/Page'
import Text from 'components/Text'
import useLocalization from 'hooks/useLocalization'
import {UserInviteKind} from 'services/V2ApiErrorMapper'
import {appLoadedAtom, userAtom} from 'atoms/party'

interface ParamTypes {
  accessCode: string
  inviteId: string
}

// v2 is used currently only for seller customers manual invite
const QuickSignIn = ({v2}: {v2?: boolean}) => {
  const {translate} = useLocalization()
  const loggedInUser = useAtomValue(userAtom)
  const appLoaded = useAtomValue(appLoadedAtom)
  const {accessCode} = useParams<ParamTypes>()
  const [user, setUser] = useState<IUserInvite>({} as IUserInvite)
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const rootService = useAtomValue(rootServiceAtom)
  const {v2ContentService} = rootService

  useEffect(() => {
    const getUser = async () => {
      try {
        const userInvite = await v2ContentService.getUserFromInvite(accessCode)

        setUser(userInvite)
        setIsLoading(false)
      } catch (e) {
        const kind = e?.error?.kind
        if (kind in UserInviteKind) {
          setError(e.message)
        } else {
          setError('Something went wrong. Please contact an administrator.')
        }
        setIsLoading(false)
      }
    }

    if (accessCode && v2ContentService) {
      getUser()
    }

    // eslint-disable-next-line
  }, [accessCode, v2ContentService])

  if (!appLoaded) {
    return (
      <Page>
        <Loader />
      </Page>
    )
  }

  if (loggedInUser) {
    return (
      <Page>
        <Box>
          <Text bold size="mlarge">
            You are already logged in as {loggedInUser?.email}.
          </Text>
          <Box justify="flex-end" margin={{top: 2}}>
            <StyledReloadingButton>{translate('Log out & continue with the invite')}</StyledReloadingButton>
          </Box>
        </Box>
      </Page>
    )
  }

  return (
    <Page hideNavigation={!error}>
      <InnerScroll>
        {(() => {
          if (isLoading) {
            return <Loader />
          }

          if (error.length > 0) {
            return <Text size={'xlarge'}>{error}</Text>
          }

          return (
            <Grid>
              <div>
                <div>
                  <Text size={'xlarge'} bold>
                    {translate('Hi, %s!', user.firstName)}
                  </Text>
                </div>
                <Box margin={{bottom: 4}}>
                  <Text size="large">
                    {translate(
                      'Thank you for accepting the invite. Your account is prepared and you only need to create a password so you can log in.',
                    )}
                  </Text>
                </Box>
                <QuickSignInForm accessCode={accessCode} user={user} v2={v2} />
              </div>
            </Grid>
          )
        })()}
      </InnerScroll>
    </Page>
  )
}

export default QuickSignIn
