import React from 'react'
import {getPlatformUrl} from 'helpers/party'
import {useHistory} from 'react-router-dom'
import useTheme from 'hooks/useTheme'
import {partyMembersAtom, selectedPartyMemberAtom} from 'atoms/party'
import {useAtom, useAtomValue, useSetAtom} from 'jotai'
import useProfileDetails from 'hooks/useProfileDetails'

const usePartyActions = () => {
  const {loadProfile} = useProfileDetails()
  const setSelectedPartyMember = useSetAtom(selectedPartyMemberAtom)
  const partyMembers = useAtomValue(partyMembersAtom)
  const history = useHistory()
  const theme = useTheme()

  const switchParty = (partyId: number, customPath?: string) => {
    const newPartyMember = partyMembers.find(pm => pm.party.id === partyId)
    setSelectedPartyMember(newPartyMember)
    localStorage.setItem('currentPartyMemberId', `${newPartyMember.id}`)
    history.push(`${getPlatformUrl(newPartyMember?.party?.partyRole, theme)}/${customPath ? customPath : ''}`)
  }

  const refreshParties = async () => {
    await loadProfile()
  }

  return {
    switchParty,
    refreshParties,
  }
}

export default usePartyActions
