import React from 'react'
import {Redirect} from 'react-router-dom'
import {PartyRole} from 'domain/IParty'
import {getPlatformUrl} from 'helpers/party'
import useLocalization from 'hooks/useLocalization'
import AccountPage from 'components/Platform/Account/AccountPage'
import AddPartyMemberForm from 'components/Platform/Account/AddPartyMemberForm'
import useTheme from 'hooks/useTheme'
import {useAtomValue} from 'jotai'
import {partyAtom} from 'atoms/party'

const validRolesUrlPrefixes = {
  [PartyRole.BUYER]: '/consumer',
  [PartyRole.PRODUCER]: '/producer',
  [PartyRole.BROKER]: '/broker',
}

const AddPartyMember = () => {
  const {translate} = useLocalization()
  const party = useAtomValue(partyAtom)
  const theme = useTheme()
  const urlPrefix = validRolesUrlPrefixes[party.partyRole]

  if (!validRolesUrlPrefixes[party.partyRole]) {
    return <Redirect to={getPlatformUrl(party?.partyRole, theme)} />
  }

  return (
    // an empty div aside to make the AddManagedUserForm take only half of the page
    <AccountPage title={translate('Add user')} description={translate('Add user to company')} aside={<div></div>}>
      <AddPartyMemberForm urlPrefix={urlPrefix}></AddPartyMemberForm>
    </AccountPage>
  )
}

export default AddPartyMember
