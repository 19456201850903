import React, {useState} from 'react'
import Page from '../Page'
import ProductMap from './Map/ProductMap'
import InnerScroll from '../InnerScroll'
import useLocalization from '../../hooks/useLocalization'
import {SiteProductionTechnology} from '../../domain/ISite'
import Filter, {IChoice} from '../Filter'
import {getPartyLink} from '../../helpers/party'
import Box from '../Box'
import {capitalize} from 'lodash-es'
import ProductItem from './Buy/CertificateDesigner/ProductItem'
import Text from 'components/Text'
import LoadingSkeleton from './Buy/Marketplace/LoadingSkeleton'
import {useAtomValue, useAtom} from 'jotai'
import {partyAtom} from 'atoms/party'
import {
  productsAtom,
  yearAtom,
  monthAtom,
  pageAtom,
  pageSizeAtom,
  countriesAtom,
  orderAtom,
  sortAtom,
  technologyAtom,
  ALL_TECHNOLOGIES_SLUG,
} from 'atoms/marketplace'
import Select from 'components/Select'
import {getChangeEventValue} from 'helpers/misc'
import Label from 'components/Label'
import useProfile from 'hooks/useProfile'
import {PaginateWithSelection} from 'components/Paginate/Paginate'
import ErrorInfo from 'components/ErrorInfo'
import Button from 'components/Button'
import Link from 'components/Link'
import MultiSelect from 'components/MultiSelect'
import {getHumanReadableCountry} from 'helpers/location'
import {MarketplaceSort, Order} from 'domain/FilterType'
import useTheme from 'hooks/useTheme'
import {uniqBy} from 'lodash-es'
import {Grid, useMediaQuery} from '@mui/material'
import {CertificateTech} from 'domain/IRegoCertificate'
import useFilters from 'hooks/useFilters'
import {getMonths, getPastYears, isPrevMonth} from 'helpers/date'
import ConfirmationDialog from 'components/ConfirmationDialog'
import {useHistory} from 'react-router-dom'

export interface IProps extends React.PropsWithChildren {
  title: string
  description?: string
  broker?: boolean
}

const RESULT_PER_PAGE_OPTIONS = [25, 50]
const TECH_FILTER = 'technology'
const MONTH_FILTER = 'month'
const YEAR_FILTER = 'year'
const COUNTRY_FILTER = 'country'
const SORT_FILTER = 'sort'
const ORDER_FILTER = 'order'
const PAGE_FILTER = 'page'
const PAGE_SIZE_FILTER = 'pageSize'

const ProductsPage: React.FC<IProps> = ({title, description, broker}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const history = useHistory()

  const largeScreen = useMediaQuery('(min-width:1725px)')
  const mediumScreen = useMediaQuery('(min-width:1375px)')

  const [confirmDialog, setConfirmDialog] = useState(false)
  const [page, setPage] = useAtom(pageAtom)
  const [pageSize, setPageSize] = useAtom(pageSizeAtom)
  const party = useAtomValue(partyAtom)
  const currentPartyCountry = party?.location?.addressCountry
  const [month, setMonth] = useAtom(monthAtom)
  const [year, setYear] = useAtom(yearAtom)
  const [countries, setCountries] = useAtom(countriesAtom)
  const [order, setOrder] = useAtom(orderAtom)
  const [sort, setSort] = useAtom(sortAtom)
  const [technology, setTechnology] = useAtom(technologyAtom)
  const urlPrefix = `/${getPartyLink(party?.partyRole)}/buy/certificate-designer/product`
  const {data: paginatedProducts, loading, error: error} = useAtomValue(productsAtom)
  const products = paginatedProducts?.data || []
  const months = getMonths()
  const years = getPastYears(1)
  const certDesignerAvailable = isPrevMonth(month, year)

  const sortOptions = [
    {value: `${MarketplaceSort.ID}/${Order.DESC}`, label: translate('Newest'), order: Order.DESC},
    {value: `${MarketplaceSort.ID}/${Order.ASC}`, label: translate('Oldest'), order: Order.ASC},
    {value: `${MarketplaceSort.PRICE}/${Order.DESC}`, label: translate('Price High to Low'), order: Order.DESC},
    {value: `${MarketplaceSort.PRICE}/${Order.ASC}`, label: translate('Price Low to High'), order: Order.ASC},
  ]

  const technologies: IChoice[] = [
    {
      name: translate('All'),
      slug: ALL_TECHNOLOGIES_SLUG,
    },
    ...Object.keys(CertificateTech).map(technology => ({
      name: translate(capitalize(SiteProductionTechnology[technology])).toUpperCase(),
      slug: technology,
    })),
  ]

  useFilters({
    single: {
      [MONTH_FILTER]: monthAtom,
      [YEAR_FILTER]: yearAtom,
      [SORT_FILTER]: sortAtom,
      [ORDER_FILTER]: orderAtom,
      [PAGE_FILTER]: pageAtom,
      [PAGE_SIZE_FILTER]: pageSizeAtom,
    },
    multi: {
      [COUNTRY_FILTER]: countriesAtom,
    },
  })

  const handleSortChange = e => {
    const value = getChangeEventValue(e)
    const [sort, order] = value.split('/')
    setSort(sort)
    setOrder(order)
  }

  return (
    <Page
      title={title}
      description={description}
      corner={
        !broker && (
          <Box direction="row" align="center" gap={2}>
            <Text color={theme.colors.light2}>{translate('Did not find what you need?')}</Text>
            <Link to={'request?technologies=ALL'}>
              <Button>{translate('Run a tender')}</Button>
            </Link>
          </Box>
        )
      }
      aside={
        <ProductMap
          title={title}
          urlPrefix={urlPrefix}
          description={translate('Overview of plant locations')}
          products={products || []}
        />
      }
      error={error && <ErrorInfo error={error} title={translate('Failed to load marketplace')} />}
    >
      <InnerScroll noRightPad>
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <Box>
            <Box margin={{top: 1}}>
              <Text color={theme.colors.light2} size="medium">
                {translate(
                  'You are viewing products from all countries. You can only buy from products in your country.',
                )}
              </Text>
            </Box>
            <Box gap={1} align="center">
              {technologies.length > 1 && (
                <Filter
                  selected={technologies.find(({slug}) => slug === technology)}
                  choices={technologies}
                  colored
                  name={TECH_FILTER}
                  transparent
                  skipDate
                  technology
                  setActiveChoice={choice => setTechnology((choice as IChoice).slug)}
                />
              )}
            </Box>
            <Box direction="row" align="center" gap={2} margin={{top: -2}}>
              <Select
                onChange={value => setMonth(getChangeEventValue(value))}
                value={month}
                margin={0}
                options={months.map(month => ({value: month, label: month}))}
              />
              <Select
                onChange={value => setYear(getChangeEventValue(value))}
                value={year}
                margin={0}
                options={years.map(year => ({value: year, label: `${year}`}))}
              />
              <Box width="200px">
                <MultiSelect
                  value={
                    countries?.map(country => ({
                      value: country,
                      label: getHumanReadableCountry(country, translate),
                    })) as any
                  }
                  noMargin
                  placeholder={translate('Any AIB country')}
                  data={uniqBy(
                    [
                      {
                        value: currentPartyCountry,
                        label: getHumanReadableCountry(currentPartyCountry, translate),
                        color: theme.colors.primaryDark,
                      },
                      ...theme.countries.map((country, index) => ({
                        value: country,
                        label: getHumanReadableCountry(country, translate),
                      })),
                    ],
                    'value',
                  )}
                  onChange={e => setCountries(getChangeEventValue(e)?.map(({value}) => value) || [])}
                  parse={value => value}
                />
              </Box>
              <Box margin={{top: -0.75}}>
                <Label text={translate('Sort by')}>
                  <Select onChange={handleSortChange} value={`${sort}/${order}`} options={sortOptions} />
                </Label>
              </Box>
            </Box>

            {products.length > 0 ? (
              <Grid container spacing={2} pb={1}>
                {products.map(product => (
                  <Grid item xl={largeScreen ? 4 : 6} xs={mediumScreen ? 6 : 12} key={product.id}>
                    <ProductItem
                      product={product}
                      disabled={!certDesignerAvailable}
                      to={certDesignerAvailable ? `${urlPrefix}/${product.id}?year=${year}&month=${month}` : undefined}
                      onClick={() => !certDesignerAvailable && setConfirmDialog(true)}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box justify="center" align="center" pad={{top: 10}}>
                <Text size="xlarge" semibold>
                  {translate(`No products for %s %s. Please select another interval or change filters.`, month, year)}
                </Text>
              </Box>
            )}
          </Box>
        )}
      </InnerScroll>

      <Box align="flex-end" justify="center">
        <PaginateWithSelection
          activePage={page}
          resultsPerPage={pageSize}
          resultsPerPageOptions={RESULT_PER_PAGE_OPTIONS}
          totalPages={paginatedProducts?.totalPages || 1}
          handlePageChange={value => setPage(value)}
          handleResultsPerPageChange={value => {
            setPageSize(value)
            setPage(1)
          }}
        />
      </Box>
      <ConfirmationDialog
        open={confirmDialog}
        title={translate('Navigate to Renewabl Tender?')}
        text={translate(
          `Procurement for this month has ended, if you require EAC's for previous months please use Renewabl tender tool`,
        )}
        setOpen={setConfirmDialog}
        acceptText={translate('Continue')}
        declineText={translate('Back')}
        onAccept={() => {
          setConfirmDialog(false)
          history.push('/consumer/buy/marketplace/request')
        }}
      />
    </Page>
  )
}

export default ProductsPage
